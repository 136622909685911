import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {api} from "../../client";
import {CourseInfo} from "../../client/type";

export const loadCourses = createAsyncThunk<
	CourseInfo[],
	number[],
	{ state: RootState }
>(
	'catalog/loadCourses',
	async (ids, thunkAPI) => {
		const courses = thunkAPI.getState().course.byId;
		const termCode = thunkAPI.getState().catalog.termCode.toString();
		const missing = ids.filter(id => !courses[id]);

		if (missing.length === 0) {
			return ids.map(id => courses[id]);
		}

		const loaded = await api.getCoursesInTerm(termCode, missing);
		return loaded;
	}
)
