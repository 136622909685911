import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { loadSelection, loadSelectionsForUser, saveSelection } from "./selection.thunks";
import {
	CourseScheduleMap,
	CourseSelectionEntry,
	CourseSelectionInfo,
	Schedule,
	ScheduleConflict
} from "../../client/type";
import { RootState } from "../index";

// Define a type for the slice state
interface SelectionState {
	id?: number;
	name?: string;
	termCode?: string;
	all: CourseSelectionInfo[];
	entries: CourseSelectionEntry[];
	byId: CourseScheduleMap;
	expanded?: number;
	loading?: string;
	error?: string;
	saving: boolean;
	saveError?: string;
}

// Define the initial state using that type
const initialState: SelectionState = {
	entries: [],
	all: [],
	byId: {},
	termCode: "1241",
	saving: false,
}

export const selectionSlice = createSlice({
	name: 'selection',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		addCourse: (state, action: PayloadAction<number>) => {
			state.entries.push({
				courseId: action.payload,
				sectionId: []
			});
		},
		removeCourse: (state, action: PayloadAction<number>) => {
			state.entries = state.entries.filter(entry => entry.courseId !== action.payload);
		},
		setSelection: (state, action: PayloadAction<{courseId: number, selectionId: number[]}>) => {
			state.entries = state.entries.map(entry => {
				if (entry.courseId === action.payload.courseId) {
					entry.sectionId = action.payload.selectionId;
				}
				return entry;
			});
		},
		resetSelectionId: (state) => {
			state.id = undefined;
			state.name = undefined;
			state.entries = [];
		},
		setExpanded: (state, action: PayloadAction<number | undefined>) => {
			state.expanded = action.payload;
		},
		replaceSelection: (state, action: PayloadAction<CourseSelectionEntry[]>) => {
			state.entries = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loadSelectionsForUser.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(loadSelectionsForUser.fulfilled, (state, action: PayloadAction<CourseSelectionInfo[]>) => {
				state.all = action.payload;
				if (action.payload.length > 0) {
					state.id = action.payload[0].selectionId;
					state.name = action.payload[0].selectionName;
					state.termCode = action.payload[0].termCode;
				}
				state.error = undefined;
				state.loading = undefined;
			})
			.addCase(loadSelectionsForUser.rejected, (state, action) => {
				state.error = action.error.message;
				state.loading = undefined;
			})
			.addCase(loadSelection.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(loadSelection.fulfilled, (state, action: PayloadAction<CourseSelectionEntry[]>) => {
				state.entries = action.payload;
				state.error = undefined;
				state.loading = undefined;
			})
			.addCase(loadSelection.rejected, (state, action) => {
				state.error = action.error.message;
				state.loading = undefined;
			})
			.addCase(saveSelection.pending, (state) => {
				state.saving = true;
				state.saveError = undefined;
			})
			.addCase(saveSelection.fulfilled, (state, action: PayloadAction<number>) => {
				state.id = action.payload;
				state.saving = false;
			})
			.addCase(saveSelection.rejected, (state, action) => {
				state.saveError = action.error.message;
				state.saving = false;
			})
	}
})

export const scheduleSelector = (state: RootState): Schedule[] => {
	const scheduleMap = state.schedule.byId;
	return state.selection.entries.flatMap((entry) => {
		const courseSchedules = scheduleMap[entry.courseId] || [];
		const course = state.course.byId[entry.courseId];

		return courseSchedules.flatMap(courseSchedule => {
			if (entry.sectionId.includes(courseSchedule.classNumber)) {
				return courseSchedule.schedules.map<Schedule>(
					s => ({
						...s,
						courseInfo: course,
						courseSchedule: courseSchedule,
					})
				);
			} else {
				return [];
			}
		});
	})
};

const scheduleOverlap = (s1: Schedule, s2: Schedule) => {
	const [s1StartDate, s1StartTime] = s1.start.split("T");
	const [s1EndDate, s1EndTime] = s1.end.split("T");
	const [s2StartDate, s2StartTime] = s2.start.split("T");
	const [s2EndDate, s2EndTime] = s2.end.split("T");
	if (!s1.day.split("").some(d => s2.day.includes(d))) {
		return false;
	}
	if (s1StartTime > s2EndTime || s2StartTime > s1EndTime) {
		return false;
	}
	if (s1StartDate > s2EndDate || s2StartDate > s1EndDate) {
		return false;
	}
	return true;
}

export const conflictSelector = (state: RootState): ScheduleConflict[] => {
	const schedules = scheduleSelector(state);
	const conflicts: ScheduleConflict[] = [];
	for (let i = 0; i < schedules.length; i++) {
		for (let j = i + 1; j < schedules.length; j++) {
			const a = schedules[i];
			const b = schedules[j];
			if (scheduleOverlap(a, b)) {
				conflicts.push({
					base: {
						scheduleId: a.scheduleId,
						courseInfoId: a.courseInfo?.courseInfoId || 0,
					},
					conflict: {
						scheduleId: b.scheduleId,
						courseInfoId: b.courseInfo?.courseInfoId || 0,
					}
				});
			}
		}
	}
	return conflicts;
}

export const {
	addCourse,
	removeCourse,
	setSelection,
	resetSelectionId,
	setExpanded,
	replaceSelection,
} = selectionSlice.actions

export default selectionSlice.reducer
