import {
	AppBar,
	Avatar,
	Box,
	Button,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Popover,
	Typography
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { UserContext } from "../components/UserProvider";
import { UserRole } from "../client/type";
import LogoutIcon from '@mui/icons-material/Logout';
import UploadIcon from '@mui/icons-material/Upload';
import { logout } from "../store/user/user.thunks";
import { TranscriptUploadDialog } from "../components/TranscriptDialog";

export const Header = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate()
	const role = useAppSelector((state) => state.user.role);
	const handleLogin = useContext(UserContext).login;
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
	const accountDropDown = Boolean(anchorEl);
	const [transcriptUploadDialogOpen, setTranscriptUploadDialogOpen] = useState(false);

	const handleLogout = () => {
		dispatch(logout());
		window.location.reload();
	}

	return (
		<div className="header">
			<AppBar position="static">
				<Box maxWidth="lg" alignItems={"center"} display={"flex"} minHeight={"48px"} width={"100%"} margin={"auto"}>
					<Typography variant="h6" component="div" sx={{flexGrow: 1}}>
						Classimple
					</Typography>
					<Box sx={{marginRight: 2}}>
						<Button color="inherit" onClick={() => navigate("/courses")}>Courses</Button>
						<Button color="inherit" onClick={() => navigate("/calendar")}>Calendar</Button>
					</Box>
					{role === UserRole.guest &&
						<Button color="inherit" onClick={handleLogin}>Login</Button>
					}
					{role === UserRole.user &&
						<>
							<Avatar onClick={(e) => setAnchorEl(e.currentTarget)}>UW</Avatar>
							<Popover
								open={accountDropDown}
								anchorEl={anchorEl}
								onClose={() => setAnchorEl(null)}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
								slotProps={{
									paper: {
										sx: {
											marginTop: '16px',
											border: '1px solid #dadde9',
										}
									}
								}}
							>
								<List>
									<ListItem disablePadding>
										<ListItemButton sx={{paddingY: '4px'}} onClick={() => setTranscriptUploadDialogOpen(true)}>
											<ListItemIcon>
												<UploadIcon/>
											</ListItemIcon>
											<ListItemText primary="Upload Transcript"/>
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton sx={{paddingY: '4px'}} onClick={handleLogout}>
											<ListItemIcon>
												<LogoutIcon/>
											</ListItemIcon>
											<ListItemText primary="Logout"/>
										</ListItemButton>
									</ListItem>
								</List>
							</Popover>
							<TranscriptUploadDialog open={transcriptUploadDialogOpen} closeDialog={() => {
								setTranscriptUploadDialogOpen(false);
								setAnchorEl(null);
							}}/>
						</>
					}
				</Box>
			</AppBar>
		</div>
	)
}
