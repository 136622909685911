import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { loadCatalog, loadSubjects } from "./catalog.thunks";
import { CourseInfo } from "../../client/type";

// Define a type for the slice state
interface CatalogState {
	termCode: number;
	courses: CourseInfo[];
	total: number;
	perPage: number;
	page: number;
	subjects?: string[];
	subject?: string;
	upperBound: number;
	lowerBound: number;

	loading?: string;
	error?: string;
}

// Define the initial state using that type
const initialState: CatalogState = {
	termCode: 1241,
	courses: [],
	total: 100,
	perPage: 10,
	page: 1,
	upperBound: 500,
	lowerBound: 0,
	subject: 'ECE'
}

export const catalogSlice = createSlice({
	name: 'catalog',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setPage: (state, action: PayloadAction<number>) => {
			state.page = action.payload;
		},
		setCourseRange: (state, action: PayloadAction<{ upperBound: number, lowerBound: number }>) => {
			state.upperBound = action.payload.upperBound;
			state.lowerBound = action.payload.lowerBound;
			state.page = 1;
		},
		setSubject: (state, action: PayloadAction<string>) => {
			state.subject = action.payload;
			state.page = 1;
		},
		setTermCode: (state, action: PayloadAction<number>) => {
			state.termCode = action.payload;
			state.page = 1;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(loadCatalog.fulfilled, (state, action) => {
				state.courses = action.payload.courses;
				state.total = action.payload.total;
				state.error = undefined;
				state.loading = undefined;
			})
			.addCase(loadCatalog.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(loadCatalog.rejected, (state, action) => {
				state.error = action.error.message;
				state.loading = undefined;
			})
			.addCase(loadSubjects.fulfilled, (state, action) => {
				state.subjects = action.payload;
			})
	}
})

export const {setPage, setCourseRange, setSubject, setTermCode} = catalogSlice.actions

export default catalogSlice.reducer
