import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {loadCourseSchedules} from "./schedule.thunks";
import {CourseScheduleMap} from "../../client/type";

// Define a type for the slice state
interface ScheduleState {
	byId: CourseScheduleMap;
	loading?: string;
	error?: string;
}

// Define the initial state using that type
const initialState: ScheduleState = {
	byId: {},
}

export const scheduleSlice = createSlice({
	name: 'schedule',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(loadCourseSchedules.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(loadCourseSchedules.fulfilled, (state, action: PayloadAction<CourseScheduleMap>) => {
				state.byId = {...state.byId, ...action.payload};
				state.error = undefined;
				state.loading = undefined;
			})
			.addCase(loadCourseSchedules.rejected, (state, action) => {
				state.error = action.error.message;
				state.loading = undefined;
			})
	}
})

export const {} = scheduleSlice.actions

export default scheduleSlice.reducer
