import { createSlice } from '@reduxjs/toolkit'
import { loadCatalog } from "../catalog/catalog.thunks";
import { loadCourses } from "./course.thunk";
import { loadRecommendationsBySimilarity } from "../recommendation/recommendation.thunk";
import { CourseInfo } from "../../client/type";

// Define a type for the slice state
interface CourseState {
	byId: { [key: number]: CourseInfo };
	loading?: string;
	error?: string;
}

// Define the initial state using that type
const initialState: CourseState = {
	byId: {},
}

export const courseSlice = createSlice({
	name: 'course',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		manualLoadCourses: (state, action: { payload: CourseInfo[] }) => {
			state.byId = {
				...state.byId,
				...action.payload.reduce((acc, course) => {
					acc[course.courseInfoId] = course;
					return acc;
				}, {} as { [id: number]: CourseInfo })
			}
			state.error = undefined;
			state.loading = undefined;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(loadCatalog.fulfilled, (state, action) => {
				state.byId = {
					...state.byId,
					...action.payload.courses.reduce((acc, course) => {
						acc[course.courseInfoId] = course;
						return acc;
					}, {} as { [id: number]: CourseInfo })
				}
			})
			.addCase(loadCourses.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(loadCourses.fulfilled, (state, action) => {
				state.byId = {
					...state.byId,
					...action.payload.reduce((acc, course) => {
						acc[course.courseInfoId] = course;
						return acc;
					}, {} as { [id: number]: CourseInfo })
				}
				state.error = undefined;
				state.loading = undefined;
			})
			.addCase(loadCourses.rejected, (state, action) => {
				state.error = action.error.message;
				state.loading = undefined;
			})
			.addCase(loadRecommendationsBySimilarity.fulfilled, (state, action) => {
				state.byId = {
					...state.byId,
					...action.payload.reduce((acc, rec) => {
						rec.recommendedCourseInfo.filter(c => !!c).forEach(info => {
							acc[info.courseInfoId] = info;
						})
						return acc;
					}, {} as { [id: number]: CourseInfo })
				}
			})
	}
})

export const {manualLoadCourses} = courseSlice.actions

export default courseSlice.reducer
