import {
	Box,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	Typography
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { useContext, useEffect, useRef } from "react";
import { removeCourse } from "../store/selection/selection.slice";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { useNavigate } from "react-router-dom";
import { CoursePopperContext } from "../components/CourseDialogProvider";
import { SelectionSelector } from "../components/SelectionSelector";
import { loadCourses } from "../store/course/course.thunk";
import { loadRecommendationsByWishList } from "../store/recommendation/recommendation.thunk";
import { RecByWishList } from "./RecByWishList";
import { SaveButton } from "../Calendar/SaveButton";
import { EditCalendar } from "@mui/icons-material";

export const WishList = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const entries = useAppSelector((state) => state.selection.entries);
	const courseById = useAppSelector((state) => state.course.byId);
	const loading = useAppSelector((state) => state.course.loading || state.selection.loading);
	const displayCourse = useContext(CoursePopperContext);
	const recByWishListRequest = useRef<NodeJS.Timeout>();
	const recByWishListFilters = useAppSelector((state) => state.recommendation.byWishListFilters);

	const handleRemoveFromWishList = (courseId: number) => {
		dispatch(removeCourse(courseId));
	}

	const handleCourseClick = (courseId: number) => {
		displayCourse(courseId);
	}

	useEffect(() => {
		dispatch(loadCourses(entries.map((entry) => entry.courseId)));

		if (entries.length === 0) {
			return;
		}
		if (recByWishListRequest.current) {
			clearTimeout(recByWishListRequest.current);
		}
		recByWishListRequest.current = setTimeout(() => {
			dispatch(loadRecommendationsByWishList());
		}, 5000);
	}, [entries])

	useEffect(() => {
		if (entries.length === 0) {
			return;
		}
		if (recByWishListRequest.current) {
			clearTimeout(recByWishListRequest.current);
		}
		dispatch(loadRecommendationsByWishList());
	}, [recByWishListFilters]);

	return (
		<Box height={"100%"}>
			<Grid padding={2} container direction={"column"} height={"100%"}>
				<Grid item md={7} overflow={"auto"} height={"100%"}
				      sx={{
					      display: "flex", flexDirection: "column", paddingBottom: "56px",
					      position: "relative", alignItems: 'center',
				      }}>
						<Grid container direction={"row"}>
							<Grid item xs>
								<Typography variant={"h6"}>Wish List</Typography>
							</Grid>
							<Grid item xs>
								<SelectionSelector/>
							</Grid>
						</Grid>
						{loading && <Box component={CircularProgress} margin={"auto"}/>}
						{!loading && entries.length === 0 &&
							<Box component={Typography} variant="caption" margin={"auto"}>Your wish list is empty</Box>
						}
						{!loading && entries.length > 0 &&
							<List sx={{
								width: '100%', overflow: 'auto'
							}}>
								{entries.filter((entry) => courseById[entry.courseId] !== undefined).map((entry) => {
									const {courseSubject, courseNumber} = courseById[entry.courseId];

									return (
										<ListItem key={entry.courseId} disablePadding>
											<ListItemButton onClick={() => handleCourseClick(entry.courseId)}>
												<ListItemText primary={`${courseSubject} ${courseNumber}`}/>
												<ListItemSecondaryAction>
													<IconButton edge="end" onClick={(e) => {
														e.stopPropagation();
														handleRemoveFromWishList(entry.courseId);
													}}>
														<RemoveIcon/>
													</IconButton>
												</ListItemSecondaryAction>
											</ListItemButton>
										</ListItem>
									)
								})}
							</List>
						}
					<Box sx={{position: "absolute", bottom: 0, marginY: 1}}>
						<SaveButton/>
						<Tooltip title={"Calendar Preview"}>
							<IconButton onClick={() => navigate('/calendar')}>
								<EditCalendar/>
							</IconButton>
						</Tooltip>
					</Box>
				</Grid>
				<Divider orientation="horizontal" flexItem/>
				<Grid item md overflow={"auto"}>
					<Box sx={{display: "flex", flexDirection: "column"}} height={"100%"}>
						<RecByWishList/>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
}
