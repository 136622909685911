import { useAppDispatch, useAppSelector } from "../store/hook";
import React, { useContext, useState } from "react";
import { CoursePopperContext } from "../components/CourseDialogProvider";
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	ListSubheader,
	MenuItem,
	Slider,
	Stack,
	TextField,
	Tooltip
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { Settings } from "@mui/icons-material";
import { updateByWishListFilters } from "../store/recommendation/recommendation.slice";
import { AutocompleteChangeReason } from "@mui/base/useAutocomplete/useAutocomplete";

export const RecByWishList = () => {
	const dispatch = useAppDispatch();
	const courseById = useAppSelector((state) => state.course.byId);
	const loading = useAppSelector((state) => state.recommendation.recWishListLoading);
	const recByWishList = useAppSelector((state) => state.recommendation.byWishList);
	const error = useAppSelector((state) => state.recommendation.recWishListError);
	const displayCourse = useContext(CoursePopperContext);

	const subjects = useAppSelector((state) => state.catalog.subjects);
	const [settingsOpen, setSettingsOpen] = useState(false);
	const recByWishListFilters = useAppSelector((state) => state.recommendation.byProfileFilters);
	const subject = recByWishListFilters?.subject;
	const lowerBound = recByWishListFilters?.lowerBound;
	const upperBound = recByWishListFilters?.upperBound;
	const [tmpSubject, setTmpSubject] = useState(subject);
	const [lowerBoundSlider, setLowerBoundSlider] = useState(lowerBound);
	const [upperBoundSlider, setUpperBoundSlider] = useState(upperBound);
	const [subjectInput, setSubjectInput] = useState(subject);

	const applyFilterUpdate = (event: any) => {
		setSettingsOpen(false);
		dispatch(updateByWishListFilters({
			subject: tmpSubject,
			lowerBound: lowerBoundSlider,
			upperBound: upperBoundSlider
		}));
	}

	const handleSubjectChange = (event: any, value: string | null, reason: AutocompleteChangeReason) => {
		if (value !== null) {
			setTmpSubject(value);
		}
	}

	const handleCourseRangeChange = (event: any, value: number | number[]) => {
		if (Array.isArray(value)) {
			setLowerBoundSlider(value[0]);
			setUpperBoundSlider(value[1]);
		}
	}

	const getCourseRangeLabel = (value: number) => {
		if (value == 500) {
			return `${value}+`;
		}
		return `${value}`;
	}

	return (
		<>
			{
				loading ? <Box component={CircularProgress} margin={"auto"}/> :
					<List
						sx={{width: '100%', overflow: 'auto'}}
						subheader={
							<ListSubheader component={Box} sx={{display: "flex", paddingX: "8px"}}>
								Discover More
								<Tooltip title={"Extend your wishlist with our recommendation system"}>
									<InfoIcon fontSize={"small"} sx={{marginY: "auto", marginLeft: "4px"}}/>
								</Tooltip>

								<Box display={"flex"} flexGrow={1}/>
								<Tooltip title={"Filters"}>
									<IconButton sx={{marginY: "auto"}} onClick={() => setSettingsOpen(true)}>
										<Settings fontSize={"small"}/>
									</IconButton>
								</Tooltip>
								<Dialog
									open={settingsOpen}
									onClose={() => setSettingsOpen(false)}
									maxWidth={"xs"}
									fullWidth
									scroll={"paper"}
								>
									<DialogTitle id="recommendations-dialog-title">
										Recommendation Settings
									</DialogTitle>
									<DialogContent>
										<Stack spacing={3} paddingX={5}>
											<FormControl fullWidth className={'row-form-control'}>
												<FormLabel className={'row-form-label'}>Subject</FormLabel>
												<Autocomplete
													inputValue={subjectInput}
													value={tmpSubject}
													size={"small"}
													options={["", ...(subjects ?? [])]}
													onInputChange={(event, newInputValue, reason) => {
														setSubjectInput(newInputValue);
														if (reason === "clear") {
															setTmpSubject("");
														}
													}}
													onChange={handleSubjectChange}
													renderOption={(props, option) => {
														return (
															<MenuItem {...props} key={option} value={option}>
																{!!option ? option : <b>All Subjects</b>}
															</MenuItem>
														)
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															placeholder={"All Subjects"}
															hiddenLabel
															variant="filled"
															value={params.inputProps.value === "" ? "All Subjects" : params.inputProps.value}
															InputProps={{
																...params.InputProps,
																disableUnderline: true,
																style: {
																	...params.inputProps.style,
																	paddingRight: "8px",
																	borderRadius: "10px",
																	width: "160px"
																},
															}}
														/>
													)}
												/>
											</FormControl>
											<Box component={FormControl}>
												<FormLabel>Course Range</FormLabel>
												<Box paddingX={2}>
													<Slider
														getAriaLabel={() => 'Course Range'}
														value={[lowerBoundSlider, upperBoundSlider]}
														onChange={handleCourseRangeChange}
														valueLabelDisplay={'auto'}
														valueLabelFormat={getCourseRangeLabel}
														min={0}
														max={500}
													/>
												</Box>
											</Box>
										</Stack>
									</DialogContent>
									<DialogActions>
										<Button onClick={applyFilterUpdate}>
											Apply
										</Button>
									</DialogActions>
								</Dialog>
							</ListSubheader>
						}
					>

						{recByWishList.map((courseId) => {
							const course = courseById[courseId];
							return (
								<ListItem key={`rec-wishilist-${courseId}`} disablePadding>
									<ListItemButton onClick={() => displayCourse(courseId)}>
										<ListItemText primary={`${course.courseSubject} ${course.courseNumber}`}/>
									</ListItemButton>
								</ListItem>
							)
						})}
					</List>
			}
		</>
	)
}
