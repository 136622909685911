import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { api } from "../../client";
import Cookies from "js-cookie";
import { resetSelectionId } from "./selection.slice";
import { CourseSelection, CourseSelectionEntry, CourseSelectionInfo } from "../../client/type";

export const loadSelectionsForUser = createAsyncThunk<
	CourseSelectionInfo[],
	undefined,
	{ state: RootState }
>(
	'selection/loadAllForUser',
	async (_, thunkAPI) => {
		const token = thunkAPI.getState().user.token;
		if (token) {
			return await api.getSavedSelectionForUser(token);
		}
		return [];
	}
)

export const loadSelection = createAsyncThunk<
	CourseSelectionEntry[],
	number,
	{ state: RootState }
>(
	'selection/load',
	async (id, thunkAPI) => {
		const token = thunkAPI.getState().user.token;
		if (token) {
			return await api.getSelection(id, token);
		}
		return [];
	}
)

export const saveSelection = createAsyncThunk<
	number,
	undefined,
	{ state: RootState }
>(
	'selection/save',
	async (_, thunkAPI) => {
		let token = thunkAPI.getState().user.token;
		const entries = thunkAPI.getState().selection.entries;
		const courseSelection: CourseSelection = {
			selectionName: "New Selection",
			termCode: "1241",
			selections: thunkAPI.getState().selection.entries
		};
		if (token) {
			const selectionId = thunkAPI.getState().selection.id;
			if (selectionId) {
				await api.updateSelection(token, selectionId, entries);
				return selectionId;
			} else {
				const response = await api.createSelection(token, courseSelection);
				return response.selectionId;
			}
		} else {
			token = (await api.guestRegister()).token;
			if (token) {
				Cookies.set('token', token);
				thunkAPI.dispatch(resetSelectionId());
			} else {
				throw new Error("Guest register failed");
			}
			const response = await api.createSelection(token, courseSelection);
			return response.selectionId;
		}
		return -1;
	}
)
