import {FormControl, MenuItem, Select} from "@mui/material";
import React from "react";
import {useAppDispatch, useAppSelector} from "../store/hook";

import {UserRole} from "../client/type";

export const SelectionSelector = () => {
	const dispatch = useAppDispatch();
	const allSelections = useAppSelector((state) => state.selection.all);
	const role = useAppSelector((state) => state.user.role);

	const value = useAppSelector((state) => state.selection.id);

	return (
		allSelections.length > 0 && role === UserRole.user ?
			<FormControl variant="standard">
				<Select
					labelId="course-list-select-label"
					id="course-list-select"
					label="Course Wish List"
					value={value}
				>
					{allSelections.map((selection) => (
						<MenuItem key={selection.selectionId} value={selection.selectionId}>{selection.selectionName}</MenuItem>
					))}
				</Select>
			</FormControl>
			:
			<></>
	)
}
