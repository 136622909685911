import { EventInput, EventSourceInput } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import { RefObject, useEffect } from "react";
import { WeekDaysMap } from "./index";
import { useAppSelector } from "../store/hook";
import { CourseSelectionEntry, Schedule } from "../client/type";
import { conflictSelector, scheduleSelector } from "../store/selection/selection.slice";

const TERM_START_DATE_MAP: { [termCode: string]: Date } = {
	"1239": new Date("2023-09-04"),
	"1241": new Date("2024-01-08"),
}

export const useCalendarController = (calendar: RefObject<FullCalendar>, entries: CourseSelectionEntry[]) => {
	const termCode = useAppSelector((state) => state.selection.termCode);

	const schedules: Schedule[] = useAppSelector(scheduleSelector);

	const conflicts = useAppSelector(conflictSelector);

	const conflictSchedules =
		conflicts.flatMap(conflict => [conflict.base.scheduleId, conflict.conflict.scheduleId])
			.filter((value, index, self) => self.indexOf(value) === index);

	const events: EventSourceInput = schedules.filter(s => !!s.courseInfo && !!s.courseSchedule).map(schedule => {
		const [startDate, startTime] = schedule.start.split("T");
		const [endDate, endTime] = schedule.end.split("T");
		const daysOfWeek: number[] = [];
		for (const day of schedule.day) {
			daysOfWeek.push(WeekDaysMap[day]);
		}

		let event: EventInput = {
			id: schedule.scheduleId.toString(),
			title: `${schedule.courseInfo?.courseSubject} ${schedule.courseInfo?.courseNumber}`,
			// backgroundColor: "green",
			extendedProps: {
				schedule,
			}
		};

		if (conflictSchedules.includes(schedule.scheduleId)) {
			// event.backgroundColor = "red";
		}

		if (startDate !== endDate) {
			return {
				...event,
				daysOfWeek,
				startTime,
				endTime,
				startRecur: startDate,
				endRecur: endDate,
			};
		} else {
			return {
				...event,
				start: schedule.start,
				end: schedule.end,
			};
		}
	})

	useEffect(() => {
		if (calendar.current) {
			if (termCode && TERM_START_DATE_MAP[termCode])
				calendar.current.getApi().gotoDate(TERM_START_DATE_MAP[termCode]);
		}
	}, [termCode, calendar.current]);

	useEffect(() => {
		const updateCalendar = () => {
			if (calendar.current) {
				let sources = calendar.current.getApi().getEvents();
				for (const source of sources) {
					if (!events.map(e => e.id).includes(source.id)) {
						console.log("removing source", source);
						source.remove();
					}
				}
				for (const event of events) {
					const existingEvent = calendar.current.getApi().getEventById(event.id!);
					if (!existingEvent) {
						calendar.current.getApi().addEvent(event);
					}
				}
				sources = calendar.current.getApi().getEvents();
				for (const source of sources) {
					if (conflictSchedules.includes(parseInt(source.id))) {
						source.setProp("backgroundColor", "red");
					} else {
						source.setProp("backgroundColor", "green");
					}
				}
			}
		};

		// Call the update function after the useEffect hook has finished executing
		setTimeout(updateCalendar, 0);
	}, [calendar, events, conflictSchedules]);
}
