import {createContext, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../store/hook";
import {loadSelection, loadSelectionsForUser} from "../store/selection/selection.thunks";
import {googleLogin, loadLocalUser, login, register} from "../store/user/user.thunks";
import {UserAuthDialog} from "./UserAuthDialog";
import {resetError} from "../store/user/user.slice";
import {UserRole} from "../client/type";

export interface UserContextType {
	login: () => void;
	register: () => void;
	google: (googleToken: string) => void;
}

export const UserContext = createContext<UserContextType>({
	login: () => {
	},
	register: () => {
	},
	google: () => {
	},
});

export const UserProvider = (props: { children: React.ReactNode }) => {
	const {children} = props;
	const dispatch = useAppDispatch();
	const token = useAppSelector((state) => state.user.token);
	const selectionId = useAppSelector((state) => state.selection.id);
	const role = useAppSelector((state) => state.user.role);

	const [mode, setMode] = useState<"Login" | "Register" | undefined>(undefined);

	useEffect(() => {
		if (selectionId) {
			dispatch(loadSelection(selectionId));
		}
	}, [selectionId]);

	useEffect(() => {
		if (token) {
			dispatch(loadSelectionsForUser());
		}
	}, [token]);

	useEffect(() => {
		dispatch(loadLocalUser());
	}, []);

	const handleSubmit = (username: string, password: string) => {
		if (mode === "Login") {
			dispatch(login({username, password}));
		} else if (mode === "Register") {
			dispatch(register({username, password}));
		}
	}

	return (
		<UserContext.Provider value={{
			login: () => setMode("Login"),
			register: () => setMode("Register"),
			google: (googleToken) => {
				dispatch(googleLogin(googleToken))
			}
		}}>
			{children}
			{role === UserRole.guest && mode &&
				<UserAuthDialog open={!!mode}
				                type={mode}
				                handleCancel={() => setMode(undefined)}
				                handleSubmit={handleSubmit}
				                handleSwitchType={() => {
					                dispatch(resetError());
													setMode(mode === "Login" ? "Register" : "Login")
				                }}
				/>
			}
		</UserContext.Provider>
	)
}
