import {
	CourseInfo,
	CourseRecommendation,
	CourseScheduleMap,
	CourseSelection,
	CourseSelectionEntry,
	CourseSelectionInfo
} from "./type";
import { Message } from "../Chatbot";
import OpenAI from "openai";
import ChatCompletion = OpenAI.ChatCompletion;

export class Api {
	base_url?: string;

	constructor(base_url?: string) {
		this.base_url = base_url;
	}

	async getCurrentTerm(): Promise<string> {
		const response = await fetch(`${this.base_url}/api/currentTerm`);
		return response.json();
	}

	async getCoursesInTerm(termCode: string, courseIds: number[]): Promise<CourseInfo[]> {
		const response = await fetch(
			`${this.base_url}/api/courses/${termCode}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(courseIds)
			}
		);
		return response.json();
	}

	async getAllCoursesInTerm(termCode: string, params?: {
		perPage?: number;
		page?: number;
		subject?: string;
		upperBound?: number;
		lowerBound?: number;
	}): Promise<{ total: number, courses: CourseInfo[] }> {
		let url = new URL(`${this.base_url}/api/courses/${termCode}`);
		if (params) {
			Object.entries(params).forEach(([key, value]) => {
				if (value !== undefined) {
					url.searchParams.append(key, value.toString());
				}
				if (key === 'upperBound' && value === 500) {
					url.searchParams.delete(key);
				}
			});
		}
		const response = await fetch(url.toString());
		return response.json();
	}

	async getSubjectCodes(): Promise<string[]> {
		const response = await fetch(`${this.base_url}/api/subjectCodes`);
		return response.json();
	}

	async getSchedules(courseInfoIds: number[]): Promise<CourseScheduleMap> {
		const response = await fetch(`${this.base_url}/api/schedules`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(courseInfoIds),
		});
		return response.json();
	}


	async validateToken(token: string): Promise<{ valid: boolean, guest: boolean }> {
		const response = await fetch(`${this.base_url}/user/getProfile`, {
				headers: {
					'x-access-token': token
				}
			}
		);
		const result = await response.json();
		if (response.status === 200) {
			return {valid: true, guest: result.guest};
		} else {
			return {valid: false, guest: true};
		}
	}

	async register(username: string, password: string): Promise<{ token: string }> {
		const response = await fetch(`${this.base_url}/user/register`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username,
				password
			})
		});
		if (response.status === 201) {
			return response.json();
		} else {
			throw new Error(await response.text());
		}
	}

	async guestRegister(): Promise<{ token: string }> {
		const response = await fetch(`${this.base_url}/user/guestRegister`, {method: 'POST'});
		return response.json();
	}

	async login(username: string, password: string): Promise<{ token: string }> {
		const response = await fetch(`${this.base_url}/user/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username,
				password
			})
		});
		if (response.status === 200) {
			return response.json();
		} else {
			throw new Error(await response.text());
		}
	}

	async googleLogin(token: string): Promise<{ token: string }> {
		const response = await fetch(`${this.base_url}/user/googleSignIn`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				token
			})
		});
		if (response.status === 200 || response.status === 201) {
			return response.json();
		} else {
			throw new Error(await response.text());
		}
	}

	async getSavedSelectionForUser(token: string, termCode?: string): Promise<CourseSelectionInfo[]> {
		const url = termCode ? `${this.base_url}/user/allSavedSelections?termCode=${termCode}` : `${this.base_url}/user/allSavedSelections`;
		const response = await fetch(
			url,
			{
				headers: {
					'x-access-token': token
				}
			}
		);
		return response.json();
	}

	async getSelection(selectionId: number, token: string): Promise<CourseSelectionEntry[]> {
		const options = {
			headers: {
				'x-access-token': token
			},
		};
		const response = await fetch(`${this.base_url}/user/selections/${selectionId}`, options);
		return response.json();
	}

	async updateSelection(token: string, selectionId: number, selection: CourseSelectionEntry[]): Promise<Response> {
		return fetch(`${this.base_url}/user/selections/${selectionId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token
			},
			body: JSON.stringify(selection)
		});
	}

	async createSelection(token: string, selection: CourseSelection): Promise<{ selectionId: number }> {
		const response = await fetch(`${this.base_url}/user/selections`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token
			},
			body: JSON.stringify(selection)
		});
		return response.json();
	}

	async getCourseRecommendationsBySimilarity(courses: number[], numToRec?: number, token?: string): Promise<CourseRecommendation[]> {
		const headers: HeadersInit = {
			'Content-Type': 'application/json',
		}
		if (token) {
			headers['x-access-token'] = token;
		}
		const response = await fetch(`${this.base_url}/rec/recommend`, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify({
				courses: courses,
				numToRec: numToRec ?? 3
			})
		});

		return response.json();
	}

	async getCourseRecommendationsByHistory(token: string, courses: number[], numToRec?: number): Promise<CourseRecommendation[]> {
		//TODO: replace with actual endpoint
		return courses.map(course => {
			return {
				"courseId": course,
				"recommendedCourseInfo":
					[
						{
							"courseInfoId": 1250,
							"courseNumber": "231",
							"courseSubject": "ECE",
							"description": "Introduction to the physical principles and electrical behavior of semiconductor materials and devices; electronic band structure, charge carriers, doping, carrier transport, pn-junctions, metal-oxide-semiconductor capacitors, transistors, and bipolar junction devices. [Offered: F,S]",
							"nextAvailableTermCode": "1235",
							"nextAvailableTermTime": "2023 S",
							"title": "Semiconductor Physics and Devices"
						}
					]
			};
		});
	}

	async getCourseRecommendationsByWishList(termCode: string, courses: number[], token?: string, filters?: {
		subject: string,
		lowerBound: number,
		upperBound: number
	}): Promise<CourseInfo[]> {
		const headers: HeadersInit = {
			'Content-Type': 'application/json',
		}
		if (token) {
			headers['x-access-token'] = token;
		}
		const response = await fetch(`${this.base_url}/rec/recWithSelection`, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify({
				termCode: termCode,
				courses: courses,
				numToRec: 6,
				start: filters?.lowerBound,
				end: filters?.upperBound,
				...(filters?.subject && filters?.subject.trim() !== "" && {subject: [filters?.subject]}),
			})
		});

		return response.json();
	}

	async resolveConflicts(termCode: string, selections: CourseSelectionEntry[], token?: string): Promise<CourseSelectionEntry[]> {
		const headers: HeadersInit = {
			'Content-Type': 'application/json',
		}
		if (token) {
			headers['x-access-token'] = token;
		}
		const response = await fetch(`${this.base_url}/user/selections/resolveConflict`, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify({
				conflictFree: false,
				termCode: termCode,
				selections: selections
			})
		});

		const result = await response.json();

		const conflictFree = result.conflictFree;

		if (!conflictFree) {
			throw new Error("Conflict resolution failed");
		}

		return result.selections;
	}

	async uploadTranscript(token: string, transcript: File): Promise<void> {
		const headers: HeadersInit = {}
		if (token) {
			headers['x-access-token'] = token;
		}
		const formData = new FormData();
		formData.append('file', transcript);
		const response = await fetch(`${this.base_url}/rec/uploadTranscript?saveToModel=true&saveToProfile=true`, {
			method: 'POST',
			headers: headers,
			body: formData
		});

		if (response.status !== 200) {
			throw new Error(await response.text());
		}
	}

	async recByProfile(token: string, termCode: string, filters: {
		subject: string,
		lowerBound: number,
		upperBound: number
	}): Promise<CourseInfo[]> {
		const headers: HeadersInit = {
			'Content-Type': 'application/json',
		}
		if (token) {
			headers['x-access-token'] = token;
		}
		const response = await fetch(`${this.base_url}/rec/recWithProfile`, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify({
				termCode: termCode,
				numToRec: 9,
				start: filters.lowerBound,
				end: filters.upperBound,
				...(filters.subject.trim() !== "" && {subject: [filters.subject]})
			})
		});

		return response.json();
	}

	async chat(messages: Message[]): Promise<string> {
		const response = await fetch(`${this.base_url}/rec/chatbot`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				messages
			})
		});
		const result: ChatCompletion = await response.json();
		const message = result?.choices?.[0]?.message?.content;
		if (message) {
			return message;
		} else {
			throw new Error("Unknown error");
		}
	}
}
