import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { api } from "../../client";
import { CourseRecommendation } from "../../client/type";
import { manualLoadCourses } from "../course/course.slice";

export const loadRecommendationsBySimilarity = createAsyncThunk<
	CourseRecommendation[],
	number[],
	{ state: RootState }
>(
	'catalog/loadRecSimilarity',
	async (courseIds, thunkAPI) => {
		const token = thunkAPI.getState().user.token;
		const recommendations = await api.getCourseRecommendationsBySimilarity(courseIds, 5, token);
		return recommendations;
	}
)

export const loadRecommendationsByHistory = createAsyncThunk<
	CourseRecommendation[],
	number[],
	{ state: RootState }
>(
	'catalog/loadRecHistory',
	async (courseIds, thunkAPI) => {
		const token = thunkAPI.getState().user.token;
		if (!token) {
			return [];
		}
		const recommendations = await api.getCourseRecommendationsByHistory(token, courseIds);
		return recommendations;
	}
)


export const loadRecommendationsByWishList = createAsyncThunk<
	number[],
	void,
	{ state: RootState }
>(
	'catalog/loadRecWishList',
	async (_, thunkAPI) => {
		const token = thunkAPI.getState().user.token;
		const courses = thunkAPI.getState().selection.entries.map(entry => entry.courseId);
		const termCode = thunkAPI.getState().selection.termCode || "1241";
		const recommendations = await api.getCourseRecommendationsByWishList(termCode, courses, token, thunkAPI.getState().recommendation.byWishListFilters);
		thunkAPI.dispatch(manualLoadCourses(recommendations));
		return recommendations.map(rec => rec.courseInfoId);
	}
)

export const loadRecommendationsByProfile = createAsyncThunk<
	number[],
	void,
	{ state: RootState }
>(
	'catalog/loadRecProfile',
	async (_, thunkAPI) => {
		const token = thunkAPI.getState().user.token;
		const termCode = thunkAPI.getState().selection.termCode || "1241";
		const filters = thunkAPI.getState().recommendation.byProfileFilters;
		if (!token) {
			return [];
		}
		const recommendations = await api.recByProfile(token, termCode, filters);
		thunkAPI.dispatch(manualLoadCourses(recommendations));
		return recommendations.map(rec => rec.courseInfoId);
	}
)
