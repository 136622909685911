import {
	Alert,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	styled
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import UploadIcon from "@mui/icons-material/Upload";
import React, { useEffect, useState } from "react";
import { api } from "../client";
import { transcriptLoaded } from "../store/user/user.slice";
import Cookies from "js-cookie";
import { useAppDispatch, useAppSelector } from "../store/hook";

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

export interface TranscriptUploadDialogProps extends DialogProps {
	closeDialog: () => void;
}

export function TranscriptUploadDialog(props: TranscriptUploadDialogProps) {
	const dispatch = useAppDispatch();
	const {
		open,
		closeDialog
	} = props;
	const [transcriptFile, setTranscriptFile] = useState<File | undefined>(undefined);
	const token = useAppSelector((state) => state.user.token);
	const [uploadingTranscript, setUploadingTranscript] = useState(false);
	const [uploadSuccess, setUploadSuccess] = useState(false);
	const [transcriptUploadError, setTranscriptUploadError] = useState(undefined);

	const handleTranscriptUpload = () => {
		setUploadingTranscript(true);
		if (transcriptFile && token) {
			api.uploadTranscript(token, transcriptFile).then((res) => {
				dispatch(transcriptLoaded(true));
				setUploadingTranscript(false);
				setUploadSuccess(true);
				Cookies.set('transcript', 'true', {expires: 365})
			}).catch((err) => {
				setUploadingTranscript(false);
				setTranscriptUploadError(err.message);
			});
		} else {
			console.log("no file or token");
		}
	}

	useEffect(() => { // On dialog close, reset state
		if (!open) {
			setTimeout(() => {
				setUploadSuccess(false);
				setUploadingTranscript(false);
				setTranscriptFile(undefined);
				setTranscriptUploadError(undefined);
			}, 1000)
		}
	}, [open])

	useEffect(() => { // On upload success, close dialog after 2 seconds
		if (uploadSuccess) {
			setTimeout(() => {
				closeDialog();
			}, 2000)
		}
	}, [uploadingTranscript])

	return (
		<Dialog
			maxWidth="xs"
			{...props}
		>
			<DialogTitle>Upload Transcript</DialogTitle>
			<DialogContent
				sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
				{!uploadSuccess && !uploadingTranscript && !transcriptUploadError &&
					<Button sx={{marginTop: "16px"}} component="label" variant="contained"
					        startIcon={transcriptFile ? <PictureAsPdfIcon/> : <UploadIcon/>}>
						{transcriptFile ? transcriptFile.name : "Choose File"}
						<VisuallyHiddenInput
							type="file"
							accept="application/pdf"
							onChange={(e) => {
								if (e.target.files) {
									setTranscriptFile(e.target.files[0]);
								}
							}}
						/>
					</Button>
				}
				{uploadingTranscript &&
					<CircularProgress/>
				}
				{uploadSuccess &&
					<Alert variant="filled" severity="success">
						Transcript uploaded successfully!
					</Alert>
				}
				{transcriptUploadError &&
					<Alert variant="filled" severity="error">
						{transcriptUploadError}
					</Alert>
				}
			</DialogContent>
			{!uploadSuccess && !uploadingTranscript && !transcriptUploadError &&
				<DialogActions>
					<Button onClick={closeDialog}>Cancel</Button>
					<Button onClick={handleTranscriptUpload}>Upload</Button>
				</DialogActions>
			}
		</Dialog>
	)
}
