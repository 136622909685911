import {
	Alert,
	AlertTitle,
	Box,
	CircularProgress,
	IconButton,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography
} from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import React, { useContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { setPage } from "../store/catalog/catalog.slice";
import { loadCatalog } from "../store/catalog/catalog.thunks";
import { addCourse } from "../store/selection/selection.slice";
import { CoursePopperContext } from "../components/CourseDialogProvider";
import { CourseInfo } from "../client/type";

export const CourseListing = () => {
	const dispatch = useAppDispatch();
	const courses = useAppSelector((state) => state.catalog.courses);
	const loading = useAppSelector((state) => state.catalog.loading);
	const error = useAppSelector((state) => state.catalog.error);
	const perPage = useAppSelector((state) => state.catalog.perPage);
	const page = useAppSelector((state) => state.catalog.page);
	const total = useAppSelector((state) => state.catalog.total);
	const lowerBound = useAppSelector((state) => state.catalog.lowerBound);
	const upperBound = useAppSelector((state) => state.catalog.upperBound);
	const wishList = useAppSelector((state) => state.selection.entries);
	const subject = useAppSelector((state) => state.catalog.subject);
	const role = useAppSelector((state) => state.user.role);
	const displayCourse = useContext(CoursePopperContext);
	const termCode = useAppSelector((state) => state.catalog.termCode);

	useEffect(() => {
		dispatch(loadCatalog());
	}, [perPage, page, lowerBound, upperBound, subject, termCode])

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		dispatch(setPage(value));
	}

	const handleCourseClick = (course: CourseInfo) => {
		displayCourse(course.courseInfoId);
	}

	const handleAddToWishList = (course: CourseInfo) => {
		course.courseInfoId && dispatch(addCourse(course.courseInfoId));
	}

	const canAddToWishList = (course: CourseInfo) => {
		return wishList.findIndex((entry) => entry.courseId === course.courseInfoId) == -1;
	}

	return (
		<Box paddingX={2} overflow={"auto"} height={"100%"}
		     sx={{
			     display: "flex", flexDirection: "column", paddingBottom: 5,
			     position: "relative", alignItems: 'center'
		     }}
		>
			{loading &&
				<Box margin={"auto"}>
					<CircularProgress/>
				</Box>
			}
			{!loading && error &&
				<Alert severity="error">
					<AlertTitle>Error</AlertTitle>
					{error}
				</Alert>
			}
			{!loading && !error && courses.length === 0 && <Typography variant={"h6"}>No courses found</Typography>}
			{!loading && !error && courses.length > 0 &&
				<TableContainer>
					<Table size="small">
						{/*<TableHead>*/}
						{/*	<TableRow>*/}
						{/*		<TableCell>Course Code</TableCell>*/}
						{/*		<TableCell>Course Name</TableCell>*/}
						{/*		<TableCell></TableCell>*/}
						{/*	</TableRow>*/}
						{/*</TableHead>*/}
						<TableBody>
							{courses.map((course) => (
								<TableRow
									key={course.courseInfoId} hover
									onClick={(e) => {
										handleCourseClick(course);
									}}
								>
									<TableCell>
										<Typography style={{pointerEvents: "none"}}>
											{`${course.courseSubject} ${course.courseNumber}`}
										</Typography>
									</TableCell>
									<TableCell sx={{textOverflow: "ellipsis"}}>
										<Typography style={{pointerEvents: "none"}}>
											{course.title}
										</Typography>
									</TableCell>
									<TableCell align={"right"}>
										<IconButton onClick={(e) => {
											e.stopPropagation();
											handleAddToWishList(course);
										}}
										            disabled={!canAddToWishList(course)}>
											<KeyboardDoubleArrowRightIcon fontSize={"small"}/>
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			}
			<Pagination
				color={"primary"}
				page={page}
				count={Math.ceil(total / perPage)} sx={{position: "absolute", bottom: 0}}
				onChange={handlePageChange}
			/>
		</Box>
	)
}
