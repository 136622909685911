export interface CourseInfo {
	courseInfoId: number;
	courseId?: string;
	courseOfferNumber?: number;
	termCode?: string;
	associatedAcademicCareer?: string;
	associatedAcademicGroupCode?: string;
	associatedAcademicOrgCode?: string;
	courseSubject: string;
	courseNumber: string;
	title: string;
	description: string;
	requirementsDescription?: string;
}

export interface CourseRecommendation {
	courseId: number;
	recommendedCourseInfo: CourseInfo[];
}

export interface CourseSchedule {
	/**
	 * Section number
	 */
	section: number;
	/**
	 * Course Component [LEC, TUT, LAB]
	 */
	component: string;
	// instructor: string;
	/**
	 * Class number
	 */
	classNumber: number;
	schedules: Schedule[];
}

export interface Schedule {
	scheduleId: number;
	/**
	 * start date-time in UTC
	 * time in the UTC string is the start time of the class
	 * start date is either the start date of the class or the start date of the recurring class
	 */
	start: string;
	/**
	 * end date-time in UTC
	 * time in the UTC string is the end time of the class
	 * if end date is the same as start date, then the class is not recurring
	 * if end date is different from start date, then the class is recurring
	 * and the class will be held every week from start date to end date on the day specified in the day field
	 */
	end: string;
	/**
	 * a string consisting of days of week [M, T, W, R, F]
	 */
	day: string;
	courseInfo?: CourseInfo;
	courseSchedule: CourseSchedule;
}

export interface ScheduleConflict {
	base: {
		scheduleId: number;
		courseInfoId: number;
	};
	conflict: {
		scheduleId: number;
		courseInfoId: number;
	};
}

export interface Subject {
	subjectCode: string;
	subjectName: string;
}

export interface UserProfile {

}

export enum UserRole {
	guest,
	user,
}

export interface CourseSelectionInfo {
	selectionId: number;
	selectionName: string;
	termCode: string;
}

export interface CourseSelectionEntry {
	courseId: number;
	sectionId: number[];
}

export interface CourseSelection {
	selectionName: string;
	termCode: string;
	selections: CourseSelectionEntry[];
}

export interface CourseScheduleMap {
	[id: string]: CourseSchedule[];
}
