import { useAppDispatch, useAppSelector } from "../store/hook";
import React, { useContext, useEffect, useState } from "react";
import { CoursePopperContext } from "../components/CourseDialogProvider";
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	ListSubheader,
	MenuItem,
	Slider,
	Stack,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { loadRecommendationsByProfile } from "../store/recommendation/recommendation.thunk";
import Cookies from "js-cookie";
import { transcriptLoaded } from "../store/user/user.slice";
import { TranscriptUploadDialog } from "../components/TranscriptDialog";
import { UserRole } from "../client/type";
import { UserContext } from "../components/UserProvider";
import { Settings } from "@mui/icons-material";
import { updateByProfileFilters } from "../store/recommendation/recommendation.slice";

export const RecWithProfile = () => {
	const dispatch = useAppDispatch();
	const courseById = useAppSelector((state) => state.course.byId);
	const subjects = useAppSelector((state) => state.catalog.subjects);
	const loading = useAppSelector((state) => state.recommendation.recProfileLoading);
	const recommendations = useAppSelector((state) => state.recommendation.byProfile);
	const error = useAppSelector((state) => state.recommendation.recProfileError);
	const transcriptAvailable = useAppSelector((state) => (!!state.user.token?.length) && state.user.transcriptLoaded);
	const loggedIn = useAppSelector((state) => state.user.role === UserRole.user);
	const displayCourse = useContext(CoursePopperContext);
	const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

	const userContext = useContext(UserContext);

	const [settingsOpen, setSettingsOpen] = useState(false);
	const recByProfileFilters = useAppSelector((state) => state.recommendation.byProfileFilters);
	const subject = recByProfileFilters?.subject;
	const lowerBound = recByProfileFilters?.lowerBound;
	const upperBound = recByProfileFilters?.upperBound;
	const [tmpSubject, setTmpSubject] = useState(subject);
	const [lowerBoundSlider, setLowerBoundSlider] = useState(lowerBound);
	const [upperBoundSlider, setUpperBoundSlider] = useState(upperBound);
	const [subjectInput, setSubjectInput] = useState(subject);

	const applyFilterUpdate = (event: any) => {
		setSettingsOpen(false);
		dispatch(updateByProfileFilters({
			subject: tmpSubject,
			lowerBound: lowerBoundSlider,
			upperBound: upperBoundSlider
		}));
	}

	const handleSubjectChange = (event: any, value: string | null) => {
		if (value !== null) {
			setTmpSubject(value);
		}
	}

	const handleCourseRangeChange = (event: any, value: number | number[]) => {
		if (Array.isArray(value)) {
			setLowerBoundSlider(value[0]);
			setUpperBoundSlider(value[1]);
		}
	}

	const getCourseRangeLabel = (value: number) => {
		if (value == 500) {
			return `${value}+`;
		}
		return `${value}`;
	}

	useEffect(() => {
		let cookie = Cookies.get('transcript');
		let uploaded = cookie === 'true';
		dispatch(transcriptLoaded(uploaded));
	}, []);

	useEffect(() => {
		transcriptAvailable && dispatch(loadRecommendationsByProfile());
	}, [transcriptAvailable, recByProfileFilters]);

	return (
		<Box sx={{
			display: "flex",
			flexDirection: "column",
		}} height={"100%"}>
			{loading && <Box component={CircularProgress} margin={"auto"}/>}
			{!loading && !transcriptAvailable &&
				<Box margin={"auto"}>
					<TranscriptUploadDialog closeDialog={() => setUploadDialogOpen(false)} open={uploadDialogOpen}/>
					<Typography variant={"caption"}>
						{!loggedIn && <>
							<Typography
								variant={"caption"} display="inline"
								sx={{
									textDecoration: 'underline',
									cursor: 'pointer'
								}}
								onClick={() => userContext.login()}
							>
								Log in
							</Typography>{" and "}
						</>
						}
						<Typography
							variant={"caption"} display="inline"
							sx={{
								textDecoration: 'underline',
								cursor: 'pointer'
							}}
							onClick={() => loggedIn && setUploadDialogOpen(true)}
						>
							Upload your transcript
						</Typography> to get personalized recommendations
					</Typography>
				</Box>
			}
			{!loading && transcriptAvailable &&
				<List
					sx={{width: '100%', overflow: 'auto'}}
					subheader={
						<ListSubheader component={Box} sx={{display: "flex", paddingX: "8px"}}>
							For you
							<Tooltip title={"Find courses based on your academic history. "}>
								<InfoIcon fontSize={"small"} sx={{marginY: "auto", marginLeft: "4px"}}/>
							</Tooltip>
							<Box display={"flex"} flexGrow={1}/>
							<Tooltip title={"Filters"}>
								<IconButton sx={{marginY: "auto"}} onClick={() => setSettingsOpen(true)}>
									<Settings fontSize={"small"}/>
								</IconButton>
							</Tooltip>
							<Dialog
								open={settingsOpen}
								onClose={() => setSettingsOpen(false)}
								maxWidth={"xs"}
								fullWidth
								scroll={"paper"}
							>
								<DialogTitle id="recommendations-dialog-title">
									Recommendation Settings
								</DialogTitle>
								<DialogContent>
									<Stack spacing={3} paddingX={5}>
										<FormControl fullWidth className={'row-form-control'}>
											<FormLabel className={'row-form-label'}>Subject</FormLabel>
											<Autocomplete
												inputValue={subjectInput}
												value={tmpSubject}
												size={"small"}
												options={["", ...(subjects ?? [])]}
												onInputChange={(event, newInputValue, reason) => {
													setSubjectInput(newInputValue);
													if (reason === "clear") {
														setTmpSubject("");
													}
												}}
												onChange={handleSubjectChange}
												renderOption={(props, option) => {
													return (
														<MenuItem {...props} key={option} value={option}>
															{!!option ? option : <b>All Subjects</b>}
														</MenuItem>
													)
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														placeholder={"All Subjects"}
														hiddenLabel
														variant="filled"
														InputProps={{
															...params.InputProps,
															disableUnderline: true,
															style: {
																paddingRight: "8px",
																borderRadius: "10px",
																width: "160px"
															},
														}}
													/>
												)}
											/>
										</FormControl>
										<Box component={FormControl}>
											<FormLabel>Course Range</FormLabel>
											<Box paddingX={2}>
												<Slider
													getAriaLabel={() => 'Course Range'}
													value={[lowerBoundSlider, upperBoundSlider]}
													onChange={handleCourseRangeChange}
													valueLabelDisplay={'auto'}
													valueLabelFormat={getCourseRangeLabel}
													min={0}
													max={500}
												/>
											</Box>
										</Box>
									</Stack>
								</DialogContent>
								<DialogActions>
									<Button onClick={applyFilterUpdate}>
										Apply
									</Button>
								</DialogActions>
							</Dialog>
						</ListSubheader>
					}
				>
					{recommendations.map((courseId) => {
						const course = courseById[courseId];
						return (
							<ListItem key={`rec-profile-${courseId}`} disablePadding>
								<ListItemButton onClick={() => displayCourse(courseId)}>
									<ListItemText primary={`${course.courseSubject} ${course.courseNumber}`}/>
								</ListItemButton>
							</ListItem>
						)
					})}
				</List>
			}
		</Box>
	)
}
