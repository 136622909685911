import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {api} from "../../client";
import {CourseScheduleMap} from "../../client/type";

export const loadCourseSchedules = createAsyncThunk<
	CourseScheduleMap,
	number[],
	{ state: RootState }
>(
	'selection/loadCourseSchedules',
	async (ids, thunkAPI) => {
		const courseSchedules = thunkAPI.getState().schedule.byId;
		const missingIds = ids.filter(id => !courseSchedules[id]);
		if (missingIds.length > 0) {
			return await api.getSchedules(ids);
		}
		return {};
	}
)
