import {useContext, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../store/hook";
import {CoursePopperContext} from "./CourseDialogProvider";
import {
	Box,
	Button,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton,
	Skeleton,
	Stack
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {addCourse, removeCourse} from "../store/selection/selection.slice";
import {loadCourses} from "../store/course/course.thunk";
import {loadRecommendationsBySimilarity} from "../store/recommendation/recommendation.thunk";


export const CourseDialog = (props: { courseId?: number }) => {
	const {courseId} = props;
	const dispatch = useAppDispatch();
	const setDisplayedCourse = useContext(CoursePopperContext);
	const course = useAppSelector(state => courseId ? state.course.byId[courseId] : undefined);
	const canAdd = useAppSelector(state => state.selection.entries.findIndex((entry) => entry.courseId === courseId) == -1);
	const recommendations = useAppSelector(state => {
		if (!courseId) return undefined;
		const recs = state.recommendation.similarById[courseId];
		const courseIdMap = state.course.byId;
		return recs?.map((rec) => courseIdMap[rec]);
	});
	const hisRecommendations = useAppSelector(state => {
		if (!courseId) return undefined;
		const recs = state.recommendation.takeTogetherById[courseId];
		const courseIdMap = state.course.byId;
		return recs?.map((rec) => courseIdMap[rec]);
	});
	const similarCoursesLoading = useAppSelector(state => state.recommendation.loading);
	const hisLoading = useAppSelector(state => state.recommendation.hisLoading);
	const [history, setHistory] = useState<number[]>([]);

	useEffect(() => {
		courseId && dispatch(loadCourses([courseId]));
		courseId && dispatch(loadRecommendationsBySimilarity([courseId]));
		// courseId && dispatch(loadRecommendationsByHistory([courseId]));
		if (courseId) {
			setHistory((prev) => {
				if (prev[prev.length - 1] !== courseId) {
					return [...prev, courseId];
				}
				return prev;
			});
		} else {
			setHistory([]);
		}
	}, [courseId])

	const handleBack = () => {
		if (history.length > 1) {
			setDisplayedCourse(history[history.length - 2]);
			setHistory((prev) => prev.slice(0, prev.length - 1));
		} else {
			setDisplayedCourse(undefined);
		}
	}

	const handleClose = () => {
		setDisplayedCourse(undefined);
	}

	const handleAddToWishList = () => {
		courseId && dispatch(addCourse(courseId));
	}

	const handleRemoveFromWishList = () => {
		courseId && dispatch(removeCourse(courseId));
	}

	return (
		<Dialog
			open={!!courseId}
			onClose={handleClose}
			maxWidth={"sm"}
			fullWidth
			scroll={"paper"}
		>
			{
				courseId && course ?
					<>
						<DialogTitle id="alert-dialog-title">
							{course.courseSubject} {course.courseNumber} {course.title}
							<IconButton
								aria-label="close"
								onClick={handleClose}
								sx={{
									position: 'absolute',
									right: 8,
									top: 8,
									color: (theme) => theme.palette.grey[500],
								}}
							>
								<CloseIcon/>
							</IconButton>
						</DialogTitle>
						<DialogContent dividers={true}>
							<Stack spacing={2}>
								<DialogContentText>
									{course.description}
								</DialogContentText>
								{similarCoursesLoading ?
									<Skeleton variant="rectangular" height={60}/>
									:
									<Box>
										<Divider textAlign="left">Similar Courses</Divider>
										<Stack direction={"row"} spacing={1} paddingTop={1}>
											{recommendations && recommendations.length > 0 &&
												recommendations.map((rec) => (
													<Chip
														variant={"filled"}
														color={"info"}
														key={rec.courseInfoId}
														label={`${rec.courseSubject} ${rec.courseNumber}`}
														onClick={() => setDisplayedCourse(rec.courseInfoId)}
														clickable
													/>
												))}
										</Stack>
									</Box>
								}
								{/*{hisLoading ?*/}
								{/*	<Skeleton variant="rectangular" height={60}/>*/}
								{/*	:*/}
								{/*	<Box>*/}
								{/*		<Divider textAlign="left">Often Take Together</Divider>*/}
								{/*		<Stack direction={"row"} spacing={1} paddingTop={1}>*/}
								{/*			{hisRecommendations && hisRecommendations.length > 0 &&*/}
								{/*				hisRecommendations.map((rec) => (*/}
								{/*					<Chip*/}
								{/*						variant={"outlined"}*/}
								{/*						key={rec.courseInfoId}*/}
								{/*						label={`${rec.courseSubject} ${rec.courseNumber}`}*/}
								{/*						onClick={() => setDisplayedCourse(rec.courseInfoId)}*/}
								{/*						clickable*/}
								{/*					/>*/}
								{/*				))}*/}
								{/*		</Stack>*/}
								{/*	</Box>*/}
								{/*}*/}
							</Stack>
						</DialogContent>
						<DialogActions>
							{history.length > 1 && <Button variant={"contained"} onClick={handleBack}>Back</Button>}
							<Box flexGrow={1}/>
							{
								canAdd ?
									<Button variant={"contained"} onClick={handleAddToWishList}>Add</Button>
									:
									<Button variant={"contained"} onClick={handleRemoveFromWishList}>Remove</Button>
							}
						</DialogActions>
					</>
					:
					<Box margin={"auto"} marginY={"20vh"}><CircularProgress/></Box>
			}
		</Dialog>
	)
}
