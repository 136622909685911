import { Checkbox, Collapse, IconButton, List, ListItemButton, ListItemText, Skeleton } from "@mui/material";
import { ExpandLess, ExpandMore, Warning } from "@mui/icons-material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { removeCourse, setExpanded, setSelection } from "../store/selection/selection.slice";
import RemoveIcon from '@mui/icons-material/Remove';
import { CourseSchedule, CourseSelectionEntry, ScheduleConflict } from "../client/type";

export const CourseListItemComponent = (props: {
	entry: CourseSelectionEntry,
	courseSchedules: CourseSchedule[],
	conflicts: ScheduleConflict[],
	removeMode: boolean,
	onConflictClick?: () => void,
}) => {
	const dispatch = useAppDispatch();
	const courseId = props.entry.courseId;
	const selectedClasses = props.entry.sectionId;
	const courseSchedules = props.courseSchedules;
	const removeMode = props.removeMode;
	const conflicts = props.conflicts;
	const courseComponents = courseSchedules.map((courseSchedule) => courseSchedule.component).filter((value, index, self) => self.indexOf(value) === index);
	const selectedComponents = courseSchedules.filter((courseSchedule) => selectedClasses.includes(courseSchedule.classNumber)).map((courseSchedule) => courseSchedule.component);
	const courseById = useAppSelector((state) => state.course.byId);
	const courseInfo = courseById[courseId];
	const expanded = useAppSelector((state) => state.selection.expanded == courseId);
	const conflictScheduleIds = useAppSelector((state) => {
		const conflictScheduleIds = conflicts.flatMap((conflict) => [conflict.base.scheduleId, conflict.conflict.scheduleId])
			.filter((value, index, self) => self.indexOf(value) === index);

		const scheduleIds = courseSchedules.flatMap((courseSchedule) => courseSchedule.schedules.map((schedule) => schedule.scheduleId));
		return conflictScheduleIds.filter((conflictScheduleId) => scheduleIds.includes(conflictScheduleId));
	});

	enum CourseSelectionStatus {
		checked,
		unchecked,
		indeterminate,
	}

	const selectionStatus = () => {
		if (selectedComponents.length === 0) {
			return CourseSelectionStatus.unchecked;
		} else if (selectedComponents.length === courseComponents.length) {
			return CourseSelectionStatus.checked;
		} else {
			return CourseSelectionStatus.indeterminate;
		}
	}

	const CourseItemCheckBox = () => {
		const status = selectionStatus();
		return (
			<Checkbox disableRipple={true}
			          checked={status === CourseSelectionStatus.checked}
			          indeterminate={status === CourseSelectionStatus.indeterminate}
			          onChange={handleCourseCheckboxChange}
			          onClick={(event) => event.stopPropagation()}
			/>
		);
	}

	const selectAll = () => {
		const missingComponents = courseComponents.filter((component) => !selectedComponents.includes(component));
		const newClassNumbers = [...selectedClasses];
		missingComponents.forEach((component) => {
			const classNumbers = courseSchedules.filter((courseSchedule) => courseSchedule.component === component).map((courseSchedule) => courseSchedule.classNumber);
			newClassNumbers.push(classNumbers[0]);
		})
		dispatch(setSelection({courseId: courseId, selectionId: newClassNumbers}));
	}

	const handleCourseCheckboxChange = () => {
		switch (selectionStatus()) {
			case CourseSelectionStatus.unchecked:
				selectAll();
				break;
			case CourseSelectionStatus.indeterminate:
				selectAll();
				break;
			case CourseSelectionStatus.checked:
				dispatch(setSelection({courseId: courseId, selectionId: []}));
				break;
		}
	}

	const handleRemove = (e: any) => {
		e.stopPropagation();
		dispatch(removeCourse(courseId));
	}

	const handleCourseItemClick = () => {
		if (expanded) {
			dispatch(setExpanded(undefined));
		} else {
			dispatch(setExpanded(courseId));
		}
	}

	const handleSectionItemClick = (classNumber: number) => {
		const newClassNumbers = selectedClasses.includes(classNumber) ?
			selectedClasses.filter(number => number !== classNumber) :
			[...selectedClasses, classNumber];
		dispatch(setSelection({courseId: courseId, selectionId: newClassNumbers}));
	}

	return (
		// course ?
		<>
			<ListItemButton onClick={() => handleCourseItemClick()}>
				<ListItemText primary={`${courseInfo.courseSubject} ${courseInfo.courseNumber}`}/>
				{!removeMode && conflictScheduleIds.length > 0 &&
					<IconButton
						color="error" disableRipple
						onClick={(e) => {
							e.stopPropagation();
							props.onConflictClick && props.onConflictClick();
						}}
					>
						<Warning/>
					</IconButton>
				}
				{removeMode ? <IconButton onClick={handleRemove}><RemoveIcon/></IconButton> : CourseItemCheckBox()}
				{!removeMode && (expanded ? <ExpandLess/> : <ExpandMore/>)}
			</ListItemButton>
			{!removeMode && <Collapse in={expanded} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{!courseSchedules && <Skeleton/>}
					{courseSchedules && courseSchedules.map((courseSchedule) => {
						const {classNumber, component, section} = courseSchedule;
						return (
							<ListItemButton key={classNumber}
							                sx={{pl: 4}}
							                onClick={(e) => {
								                e.stopPropagation();
								                classNumber && handleSectionItemClick(classNumber);
							                }}
							                disabled={selectedComponents.includes(component) && !selectedClasses.includes(classNumber)}
							>
								<ListItemText primary={`${component} ${section}`}/>
								{courseSchedule.schedules.map(s => s.scheduleId).some(id => conflictScheduleIds.includes(id)) &&
									<IconButton
										color="error" disableRipple
										onClick={(e) => {
											e.stopPropagation();
											props.onConflictClick && props.onConflictClick();
										}}
									>
										<Warning/>
									</IconButton>
								}
								<Checkbox disableRipple={true}
								          checked={selectedClasses.includes(classNumber)}
								/>
							</ListItemButton>
						)
					})}
				</List>
			</Collapse>}
		</>
		// :
		// <></>
	)
}
