import {
	Box,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Skeleton,
	Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { useParams } from "react-router-dom";
import { CourseListComponent } from "./CourseListComponent";
import { loadCourseSchedules } from "../store/schedule/schedule.thunks";
import { loadCourses } from "../store/course/course.thunk";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useCalendarController } from "./CalendarController";
import { EventContentArg } from "@fullcalendar/core";
import { CourseInfo, CourseSchedule, Schedule } from "../client/type";
import CloseIcon from "@mui/icons-material/Close";

export const WeekDaysMap: { [day: string]: number } = {
	"M": 1,
	"T": 2,
	"W": 3,
	"R": 4,
	"F": 5,
}

export const WeekDaysIcsMap: { [day: string]: string } = {
	"M": "MO",
	"T": "TU",
	"W": "WE",
	"R": "TH",
	"F": "FR",
	"S": "SA",
	"U": "SU",
}

export const Calendar = () => {
	const dispatch = useAppDispatch();
	const {id} = useParams();
	const entries = useAppSelector((state) => state.selection.entries);
	const calendarRef = React.createRef<FullCalendar>();
	const [displaySchedule, setDisplaySchedule] = React.useState<Schedule | null>(null);

	useCalendarController(calendarRef, entries);

	const renderEventContent = (eventInfo: EventContentArg) => {
		const schedule: Schedule = eventInfo.event.extendedProps.schedule;
		const course: CourseInfo = schedule.courseInfo!;
		const courseSchedule: CourseSchedule = schedule.courseSchedule!;

		return (
			<Tooltip
				title={`${course.courseSubject}${course.courseNumber} ${course.title} - ${courseSchedule.component}${courseSchedule.section}`}>
				<Box
					height={"100%"}
					width={"100%"}
					sx={{
						backgroundColor: eventInfo.backgroundColor,
						padding: 1,
						borderRadius: 1,
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						cursor: "pointer",
					}}
					onClick={() => {
						setDisplaySchedule(schedule);
					}}
				>
					{course.courseSubject}{course.courseNumber} - {courseSchedule.component}{courseSchedule.section}
				</Box>
			</Tooltip>
		)
	}

	useEffect(() => {
		dispatch(loadCourses(entries.map(entry => entry.courseId)));
		dispatch(loadCourseSchedules(entries.map(entry => entry.courseId)));
	}, [entries])

	return (
		<Grid container display={"flex"} flexGrow={1}>
			<Grid item lg={4} height={"100%"} overflow={"auto"}
			      sx={{display: "flex", flexDirection: "column"}}
			>
				<CourseListComponent entries={entries}/>
			</Grid>
			<Divider orientation="vertical" flexItem/>
			<Grid item lg height={"100%"} overflow={"auto"}
			      sx={{display: "flex", flexDirection: "column"}}
			      paddingX={2}
			>
				<FullCalendar
					ref={calendarRef}
					plugins={[timeGridPlugin]}
					initialView={"timeGridWeek"}
					headerToolbar={{
						left: 'prev,next',
						center: 'title',
						right: 'timeGridWeek,timeGridDay'
					}}
					height={"100%"}
					timeZone={"America/Toronto"}
					weekends={false}
					slotMinTime={"08:00:00"}
					slotMaxTime={"22:00:00"}
					allDaySlot={false}
					eventContent={renderEventContent}
				/>
				<Dialog
					open={!!displaySchedule}
					onClose={() => setDisplaySchedule(null)}
					maxWidth={"sm"}
					fullWidth
					scroll={"paper"}
				>
					{
						displaySchedule ?
							<>
								<DialogTitle id="schedule-dialog-title">
									{displaySchedule.courseInfo?.courseSubject} {displaySchedule.courseInfo?.courseNumber} {displaySchedule.courseInfo?.title}
									<IconButton
										aria-label="close"
										onClick={() => setDisplaySchedule(null)}
										sx={{
											position: 'absolute',
											right: 8,
											top: 8,
											color: (theme) => theme.palette.grey[500],
										}}
									>
										<CloseIcon/>
									</IconButton>
								</DialogTitle>
								<DialogContent>
									<DialogContentText id="schedule-dialog-description">
										{displaySchedule.courseSchedule?.component} {displaySchedule.courseSchedule?.section}
									</DialogContentText>
									<DialogContentText>
										{displaySchedule.start} - {displaySchedule.end}
									</DialogContentText>
								</DialogContent>
							</> :
							<Skeleton variant="rectangular" width={"100%"} height={200}/>
					}
				</Dialog>
			</Grid>
			{/*<Divider orientation="vertical" flexItem/>*/}
			{/*<Grid item lg={3} height={"100%"} overflow={"auto"}*/}
			{/*      sx={{display: "flex", flexDirection: "column"}}*/}
			{/*>*/}
			{/*	<CalendarOperations entries={entries}/>*/}
			{/*</Grid>*/}
		</Grid>
	)
}
