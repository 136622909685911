import React from "react"
import {CourseDialog} from "./CourseDialog";

export const CoursePopperContext = React.createContext((courseId?: number) => {})

export const CourseDialogProvider = (props: { children: React.ReactNode }) => {
	const [displayedCourse, setDisplayedCourse] = React.useState<number | undefined>(undefined)


	return (
		<CoursePopperContext.Provider value={setDisplayedCourse}>
			{props.children}
			<CourseDialog courseId={displayedCourse} />
		</CoursePopperContext.Provider>
	)
}
