import { Alert, CircularProgress, IconButton, Snackbar, Tooltip } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { saveSelection } from "../store/selection/selection.thunks";

export const SaveButton = () => {
	const dispatch = useAppDispatch();
	const [saveStarted, setSaveStarted] = React.useState(false);
	const saving = useAppSelector((state) => state.selection.saving);
	const error = useAppSelector((state) => state.selection.saveError);
	const [open, setOpen] = React.useState(false);

	const handleSave = async () => {
		setSaveStarted(true);
		dispatch(saveSelection());
	}

	useEffect(() => {
		if (!saving && saveStarted) {
			setOpen(true);
			setSaveStarted(false);
		}
	}, [saving]);

	return (
		<>
			<Tooltip title={"Save"}>
				<IconButton onClick={handleSave} disabled={saving}>
					{saving ? <CircularProgress size={24}/> : <SaveIcon/>}
				</IconButton>
			</Tooltip>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={() => setOpen(false)}
			>
				<Alert onClose={() => setOpen(false)} severity={error ? "error" : "success"} sx={{width: '100%'}}>
					{saving ? <CircularProgress/> : (error ?? "Selection saved!")}
				</Alert>
			</Snackbar>
		</>
	)
}
