import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { api } from "../../client";
import { CourseInfo } from "../../client/type";

export const loadCatalog = createAsyncThunk<
	{ total: number, courses: CourseInfo[] },
	undefined,
	{ state: RootState }
>(
	'catalog/load',
	async (_, thunkAPI) => {
		const catalog = thunkAPI.getState().catalog;
		const params = {
			perPage: catalog.perPage,
			page: catalog.page,
			subject: catalog.subject,
			upperBound: catalog.upperBound,
			lowerBound: catalog.lowerBound,
		}
		const termCode = catalog.termCode.toString();
		const course = await api.getAllCoursesInTerm(termCode, params);
		return course;
	}
)

export const loadSubjects = createAsyncThunk<
	string[],
	undefined,
	{ state: RootState }
>(
	'catalog/loadSubjects',
	async (_, thunkAPI) => {
		const subjects = await api.getSubjectCodes();
		return subjects;
	}
)

export const loadCurrentTerm = createAsyncThunk<
	number,
	undefined,
	{ state: RootState }
>(
	'catalog/loadCurrentTerm',
	async (_, thunkAPI) => {
		const termCode = await api.getCurrentTerm();
		return parseInt(termCode);
	}
)
