import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './App';
import './index.css';
import {store} from "./store";
import {Provider} from "react-redux";
import {GoogleOAuthProvider} from "@react-oauth/google";

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!}>
		<Provider store={store}>
			<App/>
		</Provider>
	</GoogleOAuthProvider>
);
