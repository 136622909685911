import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { googleLogin, loadLocalUser, login, logout, register, registerAsGuest } from "./user.thunks";

import { UserRole } from "../../client/type";
import Cookies from "js-cookie";

// Define a type for the slice state
interface UserState {
	token?: string;
	role: UserRole;
	loading?: string;
	error?: string;
	transcriptLoaded: boolean;
}

// Define the initial state using that type
const initialState: UserState = {
	role: UserRole.guest,
	transcriptLoaded: false
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		resetError: (state) => {
			state.error = undefined;
		},
		transcriptLoaded: (state, action: PayloadAction<boolean>) => {
			state.transcriptLoaded = action.payload;
		},
		guestToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
			state.role = UserRole.guest;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(loadLocalUser.fulfilled, (state, action: PayloadAction<{ token?: string, role: UserRole }>) => {
				const {token, role} = action.payload;
				state.token = token;
				state.role = role;
			})
			.addCase(login.pending, (state, action) => {
				state.loading = "login";
			})
			.addCase(login.fulfilled, (state, action: PayloadAction<string>) => {
				state.loading = undefined;
				state.role = UserRole.user;
				state.token = action.payload;
			})
			.addCase(login.rejected, (state, action) => {
				state.loading = undefined;
				state.error = action.error.message;
			})
			.addCase(registerAsGuest.fulfilled, (state, action: PayloadAction<string>) => {
				state.role = UserRole.guest;
				state.token = action.payload;
			})
			.addCase(register.fulfilled, (state, action: PayloadAction<string>) => {
				state.role = UserRole.user;
				state.token = action.payload;
			})
			.addCase(googleLogin.pending, (state) => {
				state.loading = "google";
			})
			.addCase(googleLogin.fulfilled, (state, action: PayloadAction<string>) => {
				state.loading = undefined;
				state.role = UserRole.user;
				state.token = action.payload;
			})
			.addCase(googleLogin.rejected, (state, action) => {
				state.loading = undefined;
				state.error = action.error.message;
			})
			.addCase(logout.fulfilled, (state) => {
				state = initialState;
				Cookies.set('transcript', "false", {expires: 365})
			})
			.addCase(logout.rejected, (state, action) => {
				state = initialState;
			})
	}
})

export const {resetError, transcriptLoaded, guestToken} = userSlice.actions

export default userSlice.reducer
