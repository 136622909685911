import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Box, CircularProgress, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import SendIcon from '@mui/icons-material/Send';
import { api } from "../client";
import Markdown from "react-markdown";

export interface Message {
	content: string;
	role: "user" | "assistant";
}

export const Chatbot = () => {
	const [open, setOpen] = useState(false);
	const [chatInput, setChatInput] = useState("");
	const [messages, setMessages] = useState<Message[]>([
		{
			content: "Hello! How can I help you today?",
			role: "assistant",
		}
	]);

	const [generating, setGenerating] = useState(false);

	const messagesEndRef = useRef<HTMLLIElement>(null);

	const handleGenerate = () => {
		const newMessages: Message[] = [...messages, {
			content: chatInput,
			role: "user",
		}];
		setMessages(newMessages);
		setChatInput("");
		setGenerating(true);
		api.chat(newMessages.slice(-10)).then((response) => {
			setMessages([...newMessages, {
				content: response,
				role: "assistant",
			}]);
			setGenerating(false);
		}).catch((error) => {
			setMessages([...newMessages, {
				content: "Opps, something went wrong. Please try again later.",
				role: "assistant",
			}]);
			setGenerating(false);
		});
	}

	useEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({behavior: "smooth"});
		}
	}, [messages]);

	// @ts-ignore
	return (
		<motion.div
			className={"chatbot"}
			animate={open ? "panel" : "button"}
			initial={"button"}
			style={{
				position: "absolute",
				bottom: "0",
				left: "0",
				width: "100%",
				height: "100%",
				zIndex: 100,
				pointerEvents: "none",
				overflow: "hidden",
			}}
		>
			<motion.div
				whileHover={open ? undefined : {scale: 1.1}}
				whileTap={open ? undefined : {scale: 0.9}}
				style={{
					position: "absolute",
					right: "20px",
					bottom: "20px",
					pointerEvents: "auto",
					overflow: "hidden",
				}}
				variants={{
					panel: {
						width: "400px",
						height: "580px",
						borderRadius: "15px",
						backgroundColor: "white",
						transition: {
							duration: 0.5,
						},
						boxShadow: "0px 0px 10px 2px rgba(0,0,0,0.1)"
					},
					button: {
						width: "50px",
						height: "50px",
						borderRadius: "50%",
						backgroundColor: "white",
						transition: {
							duration: 0.5,
						},
						boxShadow: "0px 0px 10px 4px rgba(0,0,0,0.2)"
					},
				}}
			>
				<motion.div
					style={{
						position: "relative",
						left: 0,
						top: 0,
						right: 0,
						bottom: 0,
						height: "100%",
						width: "100%",
					}}
					variants={{
						panel: {
							pointerEvents: "auto",
						},
						button: {
							pointerEvents: "none",
						},
					}}
				>
					<motion.div
						onClick={() => setOpen(true)}
						style={{
							position: "absolute",
							display: "flex",
							bottom: "0",
							right: "0",
							width: "50px",
							height: "50px",
							pointerEvents: "auto"
						}}
						variants={{
							panel: {
								opacity: 0,
								pointerEvents: "none",
								transition: {
									duration: 0.5,
								},
							},
							button: {
								opacity: 1,
								pointerEvents: "auto",
								transition: {
									delay: 0.5,
									duration: 0.5,
								},
							},
						}}
					>
						<SmartToyIcon
							style={{
								fontSize: "2.5rem",
								color: "black",
								margin: "auto",
							}}
						/>
					</motion.div>
					{open && (
						<Box display={"flex"} flexDirection={"column"} height={"100%"} width={"100%"}>
							<motion.div
								style={{
									display: "flex",
									flexDirection: "row",
									width: "100%",
									height: "50px",
									backgroundColor: "#787878",
								}}
								variants={{
									panel: {
										opacity: 1,
										transition: {
											delay: 0.5,
											duration: 0.5,
										},
									},
									button: {
										opacity: 0,
									},
								}}
							>
								<Box display={"flex"} flexGrow={1}/>
								<motion.div
									style={{
										display: "flex",
										width: "50px",
										height: "50px",
									}}
									variants={{
										panel: {
											opacity: 1,
											transition: {
												delay: 1,
												duration: 0.5,
											},
										},
										button: {
											opacity: 0,
											transition: {
												duration: 0.5,
											},
										},
									}}
								>
									<IconButton
										style={{
											color: "white",
											margin: "auto",
										}}
										onClick={() => setOpen(false)}
									>
										<RemoveIcon/>
									</IconButton>
								</motion.div>
							</motion.div>
							<Box display={"flex"} flexGrow={1} minHeight={"476px"} width={"100%"}>
								<motion.ul
									style={{
										display: "flex",
										flexDirection: "column",
										width: "100%",
										margin: 0,
										padding: "8px",
										overflow: "auto",
										wordWrap: "break-word",
									}}
									variants={{
										panel: {
											opacity: 1,
											transition: {
												delay: 1.5,
												duration: 0.5,
												staggerChildren: 0.1,
												delayChildren: 0.2,
											},
										},
										button: {
											opacity: 0,
										},
									}}
								>
									{messages.map((message, index) => (
										<motion.li
											key={index}
											style={{
												listStyleType: "none",
												padding: "8px 16px",
												marginBottom: "16px",
												borderRadius: "10px",
												backgroundColor: "lightgray",
												maxWidth: "80%",
												alignSelf: message.role === "user" ? "flex-end" : "flex-start",
											}}
											variants={{
												panel: {
													y: 0,
													opacity: 1,
													transition: {
														y: {stiffness: 1000, velocity: -100},
													},
												},
												button: {
													y: 50,
													opacity: 0,
													transition: {
														y: {stiffness: 1000},
													},
												},
											}}
											ref={index === messages.length - 1 ? messagesEndRef : null}
										>
											<Markdown
												components={{
													// @ts-ignore
													p: ({node, ...props}) => <Typography {...props} variant={"body1"}/>,
													// @ts-ignore
													li: ({node, ...props}) => <Typography {...props} component={"li"}/>,
													// @ts-ignore
													ul: ({node, ...props}) => <Typography {...props} component={"ul"}/>,
													// @ts-ignore
													ol: ({node, ...props}) => <Typography {...props} component={"ol"}/>,
													// @ts-ignore
													h1: ({node, ...props}) => <Typography {...props} variant={"h1"}/>,
													// @ts-ignore
													h2: ({node, ...props}) => <Typography {...props} variant={"h2"}/>,
													// @ts-ignore
													h3: ({node, ...props}) => <Typography {...props} variant={"h3"}/>,
													// @ts-ignore
													h4: ({node, ...props}) => <Typography {...props} variant={"h4"}/>,
													// @ts-ignore
													h5: ({node, ...props}) => <Typography {...props} variant={"h5"}/>,
													// @ts-ignore
													h6: ({node, ...props}) => <Typography {...props} variant={"h6"}/>,
												}}
											>
												{message.content}
											</Markdown>
										</motion.li>
									))}
								</motion.ul>
							</Box>
							<motion.div
								style={{
									padding: "8px",
								}}
								variants={
									{
										panel: {
											opacity: 1,
											transition: {
												delay: 1.5,
												duration: 0.5,
											},
											pointerEvents: "auto",
										},
										button: {
											opacity: 0,
											pointerEvents: "none",
										},
									}
								}
							>
								<Box
									component={"form"}
									display={"flex"} flexDirection={"row"}
									onSubmit={(e) => {
										e.preventDefault();
										if (chatInput.trim() === "") {
											return;
										}
										handleGenerate();
									}}
								>
									<TextField
										hiddenLabel
										disabled={generating}
										variant={"filled"}
										size={"small"}
										fullWidth={true}
										value={chatInput}
										onChange={(e) => setChatInput(e.target.value)}
										InputProps={{
											disableUnderline: true,
											style: {
												paddingRight: "8px",
												borderRadius: "10px",
											},
											endAdornment: (
												<InputAdornment position="end">
													{generating ?
														<CircularProgress size={24}/> :
														<IconButton type={"submit"} size={"small"}>
															<SendIcon fontSize={"small"}/>
														</IconButton>
													}
												</InputAdornment>
											)
										}}
									/>
								</Box>
							</motion.div>
						</Box>
					)}
				</motion.div>
			</motion.div>
		</motion.div>
	);
};
