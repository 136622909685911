import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
	IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { replaceSelection, scheduleSelector } from "../store/selection/selection.slice";
import { CourseInfo, CourseSchedule, CourseSelectionEntry, ScheduleConflict } from "../client/type";
import { api } from "../client";
import { Check } from "@mui/icons-material";

export interface ConflictDialogProps extends DialogProps {
	onClose: () => void;
	conflicts: ScheduleConflict[];
}

interface ConflictDetail {
	courses: CourseInfo[];
	sections: CourseSchedule[];
}

export function ConflictDialog(props: ConflictDialogProps) {
	const dispatch = useAppDispatch();
	const {conflicts} = props;
	const termCode = useAppSelector((state) => state.selection.termCode) ?? "1241";
	const token = useAppSelector((state) => state.user.token);
	const entries = useAppSelector((state) => state.selection.entries);

	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState<string | null>(null);
	const [resolution, setResolution] = useState<CourseSelectionEntry[]>([]);

	const handleResolveConflicts = () => {
		setLoading(true);
		api.resolveConflicts(termCode, entries, token).then((res) => {
			setLoading(false);
			setResolution(res);
		}).catch((err) => {
			setLoading(false);
			setError(err.message);
		});
	}

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (resolution.length > 0) {
				dispatch(replaceSelection(resolution));
				setResolution([]);
				props.onClose();
			}
		}, 1000);
		return () => clearTimeout(timeout);
	}, [resolution]);

	useEffect(() => {
		setError(null);
		setResolution([]);
		setLoading(false);
	}, [entries]);

	const conflictDetails: ConflictDetail[] = useAppSelector((state) => {
		const schedules = scheduleSelector(state);
		return conflicts.map(conflict => {
			const baseSchedule = schedules.find(s => s.scheduleId === conflict.base.scheduleId)!;
			const conflictSchedule = schedules.find(s => s.scheduleId === conflict.conflict.scheduleId)!;
			return {
				courses: [baseSchedule.courseInfo!, conflictSchedule.courseInfo!],
				sections: [baseSchedule.courseSchedule, conflictSchedule.courseSchedule],
			};
		}).filter((detail, index, self) =>
				index === self.findIndex((t) => (
					t.courses[0].courseInfoId === detail.courses[0].courseInfoId &&
					t.courses[1].courseInfoId === detail.courses[1].courseInfoId &&
					t.sections[0].section === detail.sections[0].section &&
					t.sections[1].section === detail.sections[1].section
				))
		);
	});

	return <Dialog
		maxWidth={"sm"}
		fullWidth
		scroll={"paper"}
		{...props}
	>
		<Box style={{pointerEvents: resolution.length > 0 ? "none" : "auto"}}>
			<DialogTitle>
				Conflicts
				<IconButton
					aria-label="close"
					onClick={props.onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
					}}
				>
					<CloseIcon/>
				</IconButton>
			</DialogTitle>
			<DialogContent>
				{conflictDetails.map((detail, index) => (
					<DialogContentText key={index}>
						{detail.courses[0].courseSubject} {detail.courses[0].courseNumber} {detail.sections[0].component} {detail.sections[0].section} conflicts
						with {detail.courses[1].courseSubject} {detail.courses[1].courseNumber} {detail.sections[1].component} {detail.sections[1].section}
					</DialogContentText>
				))}
				{error &&
					<DialogContentText color={"error"}>
						Opps, looks like these courses cannot be taken together. Please consult your academic advisor for
						suggestion.
					</DialogContentText>
				}
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={handleResolveConflicts}
					disabled={loading || !!error}
					endIcon={loading ? <CircularProgress size={20}/> : resolution.length > 0 ? <Check/> : !!error && <CloseIcon/>}
				>
					Resolve Conflicts
				</Button>
			</DialogActions>
		</Box>
	</Dialog>;
}
