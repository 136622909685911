import React from 'react';
import { Header } from "./Header";
import { createBrowserRouter, Outlet, redirect, RouterProvider, } from "react-router-dom";
import { Calendar } from "./Calendar";
import './App.css';
import { Box, createTheme, Paper, ThemeProvider } from "@mui/material";
import { Catalog } from "./Catalog";
import { CourseDialogProvider } from "./components/CourseDialogProvider";
import { UserProvider } from "./components/UserProvider";
import { Chatbot } from "./Chatbot";

const theme = createTheme({
	palette: {
		primary: {
			main: '#000000',
		},
		secondary: {
			main: '#FDD54F',
		},
		// action: {
		// 	active: '#681f9f',
		// },
		// info: {
		// 	main: '#23192a',
		// },
		// success: {
		// 	main: '#548468',
		// },
		// warning: {
		// 	main: '#d27430',
		// },
		// error: {
		// 	main: '#f44336',
		// },
		background: {
			default: '#FFEA3D',
		}
	},
	components: {
		MuiFormControl: {
			variants: [
				{
					props: {className: 'row-form-control'},
					style: {
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center'
					}
				}
			]
		},
		MuiFormLabel: {
			variants: [
				{
					props: {className: 'row-form-label'},
					style: {
						flexGrow: 1
					}
				},
			]
		},
		MuiSelect: {
			variants: [
				{
					props: {variant: 'filled'},
					style: {
						borderRadius: '10px',
					},
				},
				{
					props: {size: 'small'},
					style: {
						width: '140px'
					}
				}
			],
			styleOverrides: {
				filled: {
					padding: '8px'
				}
			}
		}
	}
})

export const App = () => {
	const Layout = () => (
		<ThemeProvider theme={theme}>
			<UserProvider>
				<CourseDialogProvider>
					<Box height={"100%"} display={"flex"} flexDirection={"column"} bgcolor={"background.default"}>
						<Header/>
						<Box className={"content"} component={Paper} maxWidth={"lg"}
						     sx={{flexGrow: 1, paddingY: 2, display: 'flex', overflow: 'auto', margin: 'auto', width: '100%'}}
						>
							<Outlet/>
						</Box>
					</Box>
					<Chatbot/>
				</CourseDialogProvider>
			</UserProvider>
		</ThemeProvider>
	)

	const router = createBrowserRouter([
		{
			element: <Layout/>,
			children: [
				{
					path: "/",
					loader: () => redirect("/courses"),
				},
				{
					path: "/calendar",
					element: <Calendar/>,
				},
				{
					path: "/calendar/:id",
					element: <Calendar/>,
				},
				{
					path: "/courses",
					element: <Catalog/>
				}
			]
		}
	]);

	return (
		<RouterProvider router={router}/>
	);
}
