import { Box, Button, CircularProgress, List, ListItem, ListSubheader } from "@mui/material";
import { CourseListItemComponent } from "./CourseListItemComponent";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { CourseSelectionEntry } from "../client/type";
import { CourseListActions } from "./CourseListActions";
import { ConflictDialog } from "./ConflictDialog";
import { conflictSelector } from "../store/selection/selection.slice";

export const CourseListComponent = (props: { entries: CourseSelectionEntry[] }) => {
	const {entries} = props;
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const scheduleMap = useAppSelector((state) => state.schedule.byId);
	const [editMode, setEditMode] = React.useState(false);
	const loading = useAppSelector((state) => !!state.schedule.loading || !!state.catalog.loading || !!state.selection.loading);
	const conflicts = useAppSelector((state) => conflictSelector(state));

	const [conflictDialogOpen, setConflictDialogOpen] = React.useState(false);

	return (
		<Box paddingX={2} overflow={"auto"} height={"100%"}
		     sx={{
			     display: "flex", flexDirection: "column", paddingBottom: 5,
			     position: "relative", alignItems: 'center'
		     }}
		>
			{loading ?
				<Box component={CircularProgress} margin={"auto"}/>
				:
				<List
					sx={{width: '100%', overflow: 'auto'}}
					component="nav"
					subheader={
						<ListSubheader component="div">
							Select Your Courses
						</ListSubheader>
					}
				>
					{entries.map((entry) => (
						scheduleMap[entry.courseId] &&
						<CourseListItemComponent
							key={`entry-${entry.courseId}`}
							entry={entry}
							courseSchedules={scheduleMap[entry.courseId]}
							conflicts={conflicts}
							removeMode={editMode}
							onConflictClick={() => setConflictDialogOpen(true)}
						/>
					))}
					{editMode && <ListItem>
						<Button variant={"contained"} startIcon={<AddIcon/>}
						        sx={{marginX: 'auto'}}
						        onClick={() => navigate("/courses")}
						>
							Add More
						</Button>
					</ListItem>}
				</List>
			}
			<CourseListActions onEdit={() => setEditMode(!editMode)} editMode={editMode}/>
			<ConflictDialog open={conflictDialogOpen} conflicts={conflicts} onClose={() => setConflictDialogOpen(false)}/>
		</Box>
	)
}
