import {configureStore} from '@reduxjs/toolkit'
import catalogReducer from './catalog/catalog.slice'
import selectionReducer from './selection/selection.slice'
import userReducer from './user/user.slice'
import scheduleReducer from './schedule/schedule.slice'
import courseReducer from './course/course.slice'
import recommedationReducer from './recommendation/recommendation.slice'

export const store = configureStore({
	reducer: {
		catalog: catalogReducer,
		course: courseReducer,
		recommendation: recommedationReducer,
		selection: selectionReducer,
		schedule: scheduleReducer,
		user: userReducer,
	}
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
