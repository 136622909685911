import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
	loadRecommendationsByHistory,
	loadRecommendationsByProfile,
	loadRecommendationsBySimilarity,
	loadRecommendationsByWishList
} from "./recommendation.thunk";

// Define a type for the slice state
interface RecommendationState {
	similarById: { [key: number]: number[] },
	takeTogetherById: { [key: number]: number[] },
	byWishList: number[],
	recWishListLoading?: string;
	recWishListError?: string;
	byProfile: number[],
	recProfileLoading?: string;
	recProfileError?: string;
	loading?: string;
	error?: string;
	hisLoading?: string;
	hisError?: string;
	byWishListFilters: {
		subject: string;
		lowerBound: number;
		upperBound: number;
	};
	byProfileFilters: {
		subject: string;
		lowerBound: number;
		upperBound: number;
	};
}

// Define the initial state using that type
const initialState: RecommendationState = {
	similarById: {},
	takeTogetherById: {},
	byWishList: [],
	byProfile: [],
	byWishListFilters: {
		subject: '',
		lowerBound: 0,
		upperBound: 500,
	},
	byProfileFilters: {
		subject: '',
		lowerBound: 0,
		upperBound: 500,
	}
}

export const recommendationSlice = createSlice({
	name: 'recommendation',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		updateByWishListFilters: (state, action: PayloadAction<{
			subject?: string,
			lowerBound?: number,
			upperBound?: number
		}>) => {
			state.byWishListFilters = {
				...state.byWishListFilters,
				...action.payload
			}
		},
		updateByProfileFilters: (state, action: PayloadAction<{
			subject?: string,
			lowerBound?: number,
			upperBound?: number
		}>) => {
			state.byProfileFilters = {
				...state.byProfileFilters,
				...action.payload
			}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(loadRecommendationsBySimilarity.pending, (state) => {
				state.loading = 'loading';
			})
			.addCase(loadRecommendationsBySimilarity.fulfilled, (state, action) => {
				state.similarById = {
					...state.similarById,
					...action.payload.reduce((acc, rec) => {
						acc[rec.courseId] = rec.recommendedCourseInfo.filter(c => !!c).map(info => info.courseInfoId);
						return acc;
					}, {} as { [id: number]: number[] })
				}
				state.error = undefined;
				state.loading = undefined;
			})
			.addCase(loadRecommendationsBySimilarity.rejected, (state, action) => {
				state.error = action.error.message;
				state.loading = undefined;
			})
			.addCase(loadRecommendationsByHistory.pending, (state) => {
				state.hisLoading = 'loading';
			})
			.addCase(loadRecommendationsByHistory.fulfilled, (state, action) => {
				state.takeTogetherById = {
					...state.takeTogetherById,
					...action.payload.reduce((acc, rec) => {
						acc[rec.courseId] = rec.recommendedCourseInfo.map(info => info.courseInfoId);
						return acc;
					}, {} as { [id: number]: number[] })
				}
				state.hisError = undefined;
				state.hisLoading = undefined;
			})
			.addCase(loadRecommendationsByHistory.rejected, (state, action) => {
				state.hisError = action.error.message;
				state.hisLoading = undefined;
			})
			.addCase(loadRecommendationsByWishList.pending, (state) => {
				state.recWishListLoading = 'loading';
			})
			.addCase(loadRecommendationsByWishList.fulfilled, (state, action) => {
				state.byWishList = action.payload;
				state.recWishListError = undefined;
				state.recWishListLoading = undefined;
			})
			.addCase(loadRecommendationsByWishList.rejected, (state, action) => {
				state.recWishListError = action.error.message;
				state.recWishListLoading = undefined;
			})
			.addCase(loadRecommendationsByProfile.pending, (state) => {
				state.recProfileLoading = 'loading';
			})
			.addCase(loadRecommendationsByProfile.fulfilled, (state, action) => {
				state.byProfile = action.payload;
				state.recProfileError = undefined;
				state.recProfileLoading = undefined;
			})
			.addCase(loadRecommendationsByProfile.rejected, (state, action) => {
				state.byProfile = [];
				state.recProfileError = action.error.message;
				state.recProfileLoading = undefined;
			})
	}
})

export const {updateByWishListFilters, updateByProfileFilters} = recommendationSlice.actions

export default recommendationSlice.reducer
