import {
	Autocomplete,
	Box,
	Divider,
	FormControl,
	FormLabel,
	Grid,
	MenuItem,
	Select,
	Slider,
	Stack,
	TextField
} from "@mui/material";
import React, { useEffect } from "react";
import { loadCurrentTerm, loadSubjects } from "../store/catalog/catalog.thunks";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { setCourseRange, setSubject, setTermCode } from "../store/catalog/catalog.slice";
import { CourseListing } from "./CourseListing";
import { WishList } from "./WishList";
import { RecWithProfile } from "./RecWithProfile";

type AutoCompleteOption = string;

export const Catalog = () => {
	const dispatch = useAppDispatch();

	const lowerBound = useAppSelector((state) => state.catalog.lowerBound);
	const upperBound = useAppSelector((state) => state.catalog.upperBound);
	const [lowerBoundSlider, setLowerBoundSlider] = React.useState(lowerBound);
	const [upperBoundSlider, setUpperBoundSlider] = React.useState(upperBound);
	const subjects = useAppSelector((state) => state.catalog.subjects);
	const subject = useAppSelector((state) => state.catalog.subject);
	const termCode = useAppSelector((state) => state.catalog.termCode);

	const [subjectInput, setSubjectInput] = React.useState<AutoCompleteOption>("");

	useEffect(() => {
		dispatch(loadSubjects())
		dispatch(loadCurrentTerm())
	}, [])

	const handleSubjectChange = (event: any, value: string | null) => {
		value && dispatch(setSubject(value));
	}

	const handleTermChange = (event: any) => {
		dispatch(setTermCode(event.target.value));
	}

	const handleCourseRangeChange = (event: any, value: number | number[]) => {
		if (Array.isArray(value)) {
			setLowerBoundSlider(value[0]);
			setUpperBoundSlider(value[1]);
		}
	}

	const handleCourseRangeChangeCommitted = (event: any, value: number | number[]) => {
		if (Array.isArray(value)) {
			dispatch(setCourseRange({lowerBound: value[0], upperBound: value[1]}));
		}
	}

	const getCourseRangeLabel = (value: number) => {
		if (value == 500) {
			return `${value}+`;
		}
		return `${value}`;
	}

	const filterComponent = () => {
		// @ts-ignore
		return (
			<Box padding={2} height={"100%"}>
				<Grid container direction={"column"} height={"100%"}>
					<Grid item md={5}>
						<Stack spacing={2}>
							<FormControl fullWidth className={'row-form-control'}>
								<FormLabel className={'row-form-label'}>Term</FormLabel>
								<Select
									value={termCode}
									label="Term"
									size={"small"}
									variant={"filled"}
									disableUnderline={true}
									onChange={handleTermChange}
									disabled={true}
								>
									<MenuItem value={1235}>Spring 2023</MenuItem>
									<MenuItem value={1239}>Fall 2023</MenuItem>
									<MenuItem value={1241}>Winter 2024</MenuItem>
								</Select>
							</FormControl>

							<FormControl fullWidth className={'row-form-control'}>
								<FormLabel className={'row-form-label'}>Subject</FormLabel>
								<Autocomplete
									inputValue={subjectInput}
									value={subject}
									size={"small"}
									options={subjects ?? []}
									onInputChange={(event, newInputValue) => {
										setSubjectInput(newInputValue);
									}}
									onChange={handleSubjectChange}
									renderInput={(params) => (
										<TextField
											{...params}
											hiddenLabel
											variant="filled"
											InputProps={{
												...params.InputProps,
												disableUnderline: true,
												style: {
													// @ts-ignore
													...params.InputProps.style,
													paddingRight: "8px",
													borderRadius: "10px",
													width: "140px"
												},
											}}
										/>
									)}
								/>
							</FormControl>
							<Box component={FormControl}>
								<FormLabel>Course Range</FormLabel>
								<Box paddingX={2}>
									<Slider
										getAriaLabel={() => 'Course Range'}
										value={[lowerBoundSlider, upperBoundSlider]}
										onChange={handleCourseRangeChange}
										onChangeCommitted={handleCourseRangeChangeCommitted}
										valueLabelDisplay={'auto'}
										valueLabelFormat={getCourseRangeLabel}
										min={0}
										max={500}
									/>
								</Box>
							</Box>
						</Stack>
					</Grid>
					<Divider orientation={"horizontal"} flexItem={true}/>
					<Grid item md style={{overflow: "auto"}}>
						<RecWithProfile/>
					</Grid>
				</Grid>
			</Box>
		)
	}

	return (
		<Grid container display={"flex"} flexGrow={1} sx={{height: "100%"}}>
			<Grid item lg={3} height={"100%"} overflow={"auto"}
			      sx={{display: "flex", flexDirection: "column"}}
			>
				{filterComponent()}
			</Grid>
			<Divider orientation="vertical" flexItem/>
			<Grid item lg={6} height={"100%"} overflow={"auto"}
			      sx={{display: "flex", flexDirection: "column"}}
			>
				<CourseListing/>
			</Grid>
			<Divider orientation="vertical" flexItem/>
			<Grid item lg height={"100%"} overflow={"auto"}
			      sx={{display: "flex", flexDirection: "column"}}
			>
				<WishList/>
			</Grid>
		</Grid>
	)
}
