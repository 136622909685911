import { Alert, Box, CircularProgress, IconButton, Snackbar, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { MouseEventHandler, useState } from "react";
import { useAppSelector } from "../store/hook";
import { WeekDaysIcsMap } from "./index";
import { createEvents, EventAttributes } from "ics";
import { Download } from "@mui/icons-material";
import { SaveButton } from "./SaveButton";
import moment from "moment-timezone";

export interface CourseListActionsProps {
	onEdit: MouseEventHandler
	editMode: boolean
}

export const CourseListActions = (props: CourseListActionsProps) => {
	const {onEdit, editMode} = props;
	const [exportLoading, setExportLoading] = useState(false);
	const [exportError, setExportError] = useState<String | undefined>(undefined);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const events: EventAttributes[] = useAppSelector((state) => {
		const scheduleMap = state.schedule.byId;
		const events: EventAttributes[] = state.selection.entries.flatMap((entry) => {
			const courseSchedules = scheduleMap[entry.courseId] || [];
			const course = state.course.byId[entry.courseId];

			return courseSchedules.flatMap(courseSchedule => {
				if (entry.sectionId.includes(courseSchedule.classNumber)) {
					return courseSchedule.schedules.map(schedule => {
						const title = `${course.courseSubject}${course.courseNumber} - ${courseSchedule.component}${courseSchedule.section}`;

						const [startDate, startTime] = schedule.start.split("T");
						const [endDate, endTime] = schedule.end.split("T");
						const firstEventStartTime = moment(schedule.start,);
						const firstEventEndTime = moment(`${startDate}T${endTime}`);

						const durationMillis = firstEventEndTime.diff(firstEventStartTime);

						const hours = moment.duration(durationMillis).hours();
						const minutes = moment.duration(durationMillis).minutes();
						const seconds = moment.duration(durationMillis).seconds();

						const daysOfWeek: string[] = [];
						for (const day of schedule.day) {
							daysOfWeek.push(WeekDaysIcsMap[day]);
						}

						let untilDate = new Date(endDate);
						untilDate.setDate(untilDate.getDate() + 1);
						const recurrenceRule = startDate === endDate ? undefined : `FREQ=WEEKLY;BYDAY=${daysOfWeek.join(",")};INTERVAL=1;UNTIL=${untilDate.toISOString().replace(/[-:]/g, "").split(".")[0] + "Z"}`;

						const startArray = moment(schedule.start).format('YYYY-M-D-H-m').split("-").map((a) => parseInt(a));

						const event: EventAttributes = {
							title,
							// @ts-ignore
							start: startArray,
							startInputType: "local",
							startOutputType: "local",
							duration: {
								hours,
								minutes,
								seconds,
							},
							recurrenceRule,
						}

						return event;
					});
				} else {
					return [];
				}
			});
		});
		return events;
	});

	const handleShare = () => {
		setOpen(true);
		setExportLoading(true);
		const {error, value} = createEvents(events);

		if (error) {
			setExportLoading(false);
			setExportError(error.message);
			return;
		}

		if (!value) {
			setExportLoading(false);
			setExportError("Unknown Error");
			return;
		}

		const blob = new Blob([value], {type: "text/plain"});
		const url = URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.href = url;
		anchor.download = "winter 2024.ics";

		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);

		URL.revokeObjectURL(url);
		setMessage("Download Started");
		setExportLoading(false);
	}

	const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const severity = exportError ? "error" : "success";

	return (
		<Box sx={{position: "absolute", bottom: 0}}>
			<SaveButton/>
			<Tooltip title={"Edit wishlist"}>
				<IconButton onClick={onEdit} sx={{background: editMode ? "rgba(0, 0, 0, 0.08)" : ""}}>
					<EditIcon/>
				</IconButton>
			</Tooltip>
			<Tooltip title={"Export to calendar"}>
				<IconButton onClick={handleShare}>
					<Download/>
				</IconButton>
			</Tooltip>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
					{exportLoading ? <CircularProgress/> : (exportError ?? message)}
				</Alert>
			</Snackbar>
		</Box>
	)
}
