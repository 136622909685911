import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../index";
import Cookies from "js-cookie";
import { api } from "../../client";
import { resetSelectionId } from "../selection/selection.slice";
import { UserRole } from "../../client/type";

export const loadLocalUser = createAsyncThunk<
	{ token?: string, role: UserRole },
	undefined,
	{ state: RootState }
>(
	'user/local',
	async (_, thunkAPI) => {
		let token = Cookies.get('token');
		let valid = false;
		let guest = true;
		if (token) {
			const response = await api.validateToken(token);
			valid = response.valid;
			guest = response.guest;
		} else {
			const response = await api.guestRegister();
			token = response.token;
			if (token) {
				Cookies.set('token', token);
				thunkAPI.dispatch(resetSelectionId());
				guest = true;
			}
		}

		return {token: token, role: guest ? UserRole.guest : UserRole.user};
	}
)

export const login = createAsyncThunk<
	string,
	{ username: string, password: string },
	{ state: RootState }
>(
	'user/login',
	async ({username, password}, thunkAPI) => {
		const response = await api.login(username, password);
		const token = response.token;
		if (token) {
			Cookies.set('token', token);
			thunkAPI.dispatch(resetSelectionId());
		} else {
			throw new Error("Login failed");
		}
		return token;
	}
)

export const registerAsGuest = createAsyncThunk<
	string,
	undefined,
	{ state: RootState }
>(
	'user/registerAsGuest',
	async (_, thunkAPI) => {
		const response = await api.guestRegister();
		const token = response.token;
		if (token) {
			Cookies.set('token', token);
			thunkAPI.dispatch(resetSelectionId());
		} else {
			throw new Error("Guest register failed");
		}
		return token;
	}
)

export const register = createAsyncThunk<
	string,
	{ username: string, password: string },
	{ state: RootState }
>(
	'user/register',
	async ({username, password}, thunkAPI) => {
		const response = await api.register(username, password);
		const token = response.token;
		if (token) {
			Cookies.set('token', token);
			thunkAPI.dispatch(resetSelectionId());
		} else {
			throw new Error("Register failed");
		}
		return token;
	}
)

export const googleLogin = createAsyncThunk<
	string,
	string,
	{ state: RootState }
>(
	'user/google',
	async (googleToken, thunkAPI) => {
		const response = await api.googleLogin(googleToken);
		const token = response.token;
		if (token) {
			Cookies.set('token', token);
			thunkAPI.dispatch(resetSelectionId());
		} else {
			throw new Error("Google login failed");
		}
		return token;
	}
)

export const logout = createAsyncThunk<
	void,
	undefined,
	{ state: RootState }
>(
	'user/logout',
	async (_, thunkAPI) => {
		const token = thunkAPI.getState().user.token;
		if (token) {
			Cookies.remove('token');
			thunkAPI.dispatch(resetSelectionId());
		}
	}
)
