import {
	Alert,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogProps,
	DialogTitle,
	Divider,
	IconButton,
	Stack,
	TextField,
	Typography
} from "@mui/material";
import { useContext, useState } from "react";
import { useAppSelector } from "../store/hook";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { GoogleLogin } from "@react-oauth/google";
import { UserContext } from "./UserProvider";

export interface UserAuthDialogProps extends DialogProps {
	handleSubmit: (email: string, password: string) => void;
	handleCancel: () => void;
	handleSwitchType: () => void;
	type: "Login" | "Register";
}

export const UserAuthDialog = (props: UserAuthDialogProps) => {
	const {handleSubmit, handleCancel, type, handleSwitchType} = props;

	const {google} = useContext(UserContext);

	const loading = useAppSelector(state => state.user.loading);
	const error = useAppSelector(state => state.user.error);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"xs"}
			{...props}
		>
			<DialogTitle display={"flex"}>
				<Box flexGrow={1}/>
				<IconButton
					onClick={handleCancel}
				>
					<CloseIcon/>
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Stack
					component={"form"}
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit(email, password);
					}}
					paddingTop={2}
					spacing={2}
				>
					<TextField
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required={true}
						autoComplete={"email"}
						autoFocus
						margin="dense"
						id="email"
						label="Email"
						type="email"
						fullWidth
						variant="outlined"
					/>
					<TextField
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required={true}
						autoComplete={"password"}
						margin="dense"
						id="password"
						label="Password"
						type="password"
						fullWidth
						variant="outlined"
					/>
					{error && <Alert severity="error">{error}</Alert>}
					{type === "Register" &&
						<Box>
							<Checkbox/>
							I agree to the <Link to={"#"}>Terms of Service</Link> and <Link to={"#"}>Privacy Policy</Link>
						</Box>
					}
					<Button
						variant={"contained"}
						type={"submit"}
						startIcon={loading ? <CircularProgress size={20}/> : undefined}
						disabled={!!loading}
					>
						{type}
					</Button>
					<Divider>OR</Divider>
					<Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
						<GoogleLogin
							text={"continue_with"}
							size={"large"}
							onSuccess={credentialResponse => {
								google(credentialResponse.credential!);
							}}
							onError={() => {
								console.log('Google Login Failed');
							}}
						/>
					</Box>

					{type === "Register" &&
						<Typography variant={"body2"}>
							Already have an account? <Link to={"#"} onClick={handleSwitchType}>Login</Link>
						</Typography>
					}
					{type === "Login" &&
						<Typography variant={"body2"}>
							Don't have an account? <Link to={"#"} onClick={handleSwitchType}>Register</Link>
						</Typography>
					}

				</Stack>
			</DialogContent>
		</Dialog>
	)
}
